import { Subject } from 'rxjs'
export type FollowingProfile = {
  from: string
  to: string
  followState: boolean
  type: FollowType
}

export type PostActionType = 'create' | 'modify' | 'delete'
export type FollowType = 'user' | 'tag' | 'dao'

export enum PostActionEnum {
  create = 'create',
  modify = 'modify',
  delete = 'delete',
}
export enum FollowTypeEnum {
  user = 'user',
  tag = 'tag',
  dao = 'dao',
}

export type PostActionModel = {
  owner: string
  type: PostActionType
  daoId?: string
}

class Dispatcher {
  $profileChanged = new Subject<{ from: string; to: string; followState: boolean; type: FollowType }>()
  $postChanged = new Subject<PostActionModel>()
  $blindPostChanged = new Subject<{ status: boolean; postId: string }>()
  $commentCreated = new Subject<{comment: any}>()
  $upvoteCreated = new Subject<{like: any}>()
}

export const dispatcher = new Dispatcher()
