import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { MAX_SIZE_IMAGE_UPLOAD_BY_BYTE, MAX_SIZE_IMAGE_UPLOAD_BY_MB, MB_TO_BYTE } from '@/constants'
import { postDialogHelper } from '@/helper/post-helper'
import { formatStringNoExtraSpace } from '@/helper/utils'
import { PostModel, TagModel } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { chunk, flattenDeep, isEmpty, map, some, sumBy } from 'lodash'
import { action, computed, observable, runInAction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { v4 as uuidv4 } from 'uuid'

export interface EditorBlock {
  tagLocation?: any
  type?: string
  id?: number
  options?: any
  pureFile?: any
  fileSource?: any
  pureFiles?: any
  fileSources?: any
  htmlContent?: string
  rawContent?: string
  insertOgInfo?: any
  link?: string
  letterCount?: number
}

export interface PostMediaSource {
  id?: string
  type?: string
  source?: string
  name?: string
  sizeByByte: number
  sizeByMb?: number
  isExceededSize?: boolean
  isStopVideo?: boolean
  isEditMediaSource?: boolean
  durationBySecond?: number
}

export interface PostMediaFile {
  id?: string
  file?: File
  isEditMediaFile?: boolean
}

export class EditorHandler {
  @observable editorBlocks: EditorBlock[] = []
  @observable editorBlockCount = 0
  @observable isReadyVideoDuration = false

  @observable tagLocation: any = undefined
  @observable searchLocationResult = [] as any
  @observable taggedLocation = undefined

  @observable tagTextSearch = ''
  @observable tagsSelected: string[] = []
  @observable tagList: TagModel[] = []
  @observable linkInserting = false
  @observable tagContentList: string[] = []
  @observable tagLocationDialog = false
  @observable fetchPlaceDetailLoading = false
  @observable placeLoadingIndex = 0

  constructor() {
    //
  }

  @action clearEditorData() {
    this.tagTextSearch = ''
    this.tagsSelected = []
    this.isReadyVideoDuration = false
    this.tagLocation = undefined
    this.initEditorBlocks()
  }
  @action setEditorData(post: PostModel) {
    if (post.tags) this.tagsSelected = map(post.tags, 'content')
    const content = post.data?.content
    this.editorBlockCount = 0
    const editorBlocks: EditorBlock[] = []
    const normalizedMedias = postDialogHelper.getNormalizedMedias(post.medias)
    content?.map((block) => {
      const editorBlock: EditorBlock = {
        type: `${block.type}-block`,
        id: this.editorBlockCount,
        letterCount: 0,
      }
      const chuckFileSources = [] as any
      const chuckPureFiles = [] as any
      if (block.type === 'image') {
        block.files.map((chuckFile) => {
          const chuckFileSource: PostMediaSource[] = []
          const chuckPureFile: PostMediaFile[] = []
          chuckFile.map((fileId) => {
            const file = normalizedMedias[fileId]
            chuckFileSource.push({
              id: fileId,
              type: block.type,
              source: file.url,
              sizeByByte: file.size,
              sizeByMb: file.sizeByMb,
              isExceededSize: false,
              isStopVideo: true,
              isEditMediaSource: true,
            })
            chuckPureFile.push({
              id: fileId,
              isEditMediaFile: true,
            })
          })
          chuckFileSources.push(chuckFileSource)
          chuckPureFiles.push(chuckPureFile)
        })
        editorBlock.fileSources = chuckFileSources
        editorBlock.pureFiles = chuckPureFiles
      } else if (block.type === 'video') {
        const fileId = block.files[0]
        const file = normalizedMedias[fileId]
        const fileSource: PostMediaSource = {
          id: fileId,
          type: block.type,
          source: file.url,
          sizeByByte: file.size,
          sizeByMb: file.sizeByMb,
          isExceededSize: false,
          isStopVideo: true,
          isEditMediaSource: true,
        }
        const pureFile: PostMediaFile = { id: fileId, isEditMediaFile: true }
        editorBlock.fileSource = fileSource
        editorBlock.pureFile = pureFile
      } else if (block.type === 'link') {
        editorBlock.insertOgInfo = {}
        editorBlock.link = block?.link
      } else {
        editorBlock.htmlContent = block.htmlContent
        editorBlock.options = { bounds: `#quill-container-${this.editorBlockCount}` }
        editorBlock.letterCount = formatStringNoExtraSpace(block.rawContent || '').length
      }
      editorBlocks.push(editorBlock)
      this.editorBlockCount++
    })
    this.editorBlocks = editorBlocks

    if (this.tagLocation) {
      const locationBlock = {
        type: 'location-block',
        id: this.editorBlockCount,
        options: { bounds: `#quill-container-${this.editorBlockCount}` },
        tagLocation: this.tagLocation,
        letterCount: 0,
      }
      this.editorBlockCount++
      this.editorBlocks = [...this.editorBlocks, locationBlock]
    }
  }
  @asyncAction *initEditorBlocks() {
    this.editorBlockCount = 0
    this.editorBlocks = [
      {
        type: 'text-block',
        id: this.editorBlockCount,
        options: { bounds: `#quill-container-${this.editorBlockCount}` },
        letterCount: 0,
      },
    ]
    this.editorBlockCount += 1
  }
  @action clearMediaInput(id) {
    const dt = new DataTransfer() as any
    const mediaInput = document.getElementById(id) as any
    mediaInput.files = dt.files
  }
  @action addEditorBlocks(index, media: 'image' | 'video' | 'link') {
    const mediaBlock = {
      type: `${media}-block`,
      id: this.editorBlockCount,
      options: '',
      letterCount: 0,
    }
    const textBlock = {
      type: 'text-block',
      id: this.editorBlockCount + 1,
      options: { bounds: `#quill-container-${this.editorBlockCount + 2}` },
      letterCount: 0,
    }
    this.editorBlocks.splice(index + 1, 0, mediaBlock, textBlock)
    this.editorBlockCount += 2
  }
  @action removeEditorBlocks(index, amount = 1) {
    this.editorBlocks.splice(index, amount)
  }

  // text block
  @action addTextBlock(index) {
    this.editorBlocks.splice(index, 0, {
      type: 'text-block',
      id: this.editorBlockCount,
      options: { bounds: `#quill-container-${this.editorBlockCount}` },
      letterCount: 0,
    })
    this.editorBlockCount++
  }
  @action changeTextBlock(id, text) {
    const index = this.editorBlocks.findIndex((item) => item.id === id)
    if (index === -1) return
    const validText = formatStringNoExtraSpace(text || '')
    this.editorBlocks[index].letterCount = validText.length
  }
  @action updateTextBlock(id, htmlContent, rawContent) {
    const index = this.editorBlocks.findIndex((item) => item.id === id)
    if (index === -1) return
    this.editorBlocks[index]['htmlContent'] = htmlContent
    this.editorBlocks[index]['rawContent'] = rawContent
  }

  // video block
  @asyncAction *getFileDurationBySecond(fileURL, type) {
    if (type !== 'video') return 0
    const video = document.createElement('video')
    video.src = fileURL
    this.isReadyVideoDuration = false
    video.ondurationchange = () => {
      runInAction(() => {
        this.isReadyVideoDuration = true
      })
    }
    yield when(() => this.isReadyVideoDuration)
    return video?.duration || 0
  }
  @asyncAction *changeVideoBlockFileInput(file: File, id) {
    const editorIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorIndex === -1) return

    const verifiedMessage = postDialogHelper.verifyVideoFileInput(id, file, this.editorBlocks)
    if (!verifiedMessage.status) {
      snackController.error(verifiedMessage.error)
      this.clearMediaInput(`editor-video-input-${id}`)
      return
    }

    const type = file.type.includes('video') ? 'video' : 'image'
    const fileURL = URL.createObjectURL(file)
    const durationBySecond = yield this.getFileDurationBySecond(fileURL, type)
    const mediaId: any = uuidv4()
    const fileSource = {
      id: mediaId,
      type,
      source: fileURL,
      name: file.name,
      sizeByByte: file.size,
      sizeByMb: Math.round(file.size / MB_TO_BYTE),
      isExceededSize: file.size > MAX_SIZE_IMAGE_UPLOAD_BY_BYTE,
      isStopVideo: true,
      durationBySecond,
    } as PostMediaSource
    const pureFile = { id: mediaId, file } as PostMediaFile
    this.editorBlocks[editorIndex]['fileSource'] = fileSource
    this.editorBlocks[editorIndex]['pureFile'] = pureFile
  }
  @action removeVideoBlockFileInput(id) {
    const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorBlockIndex == -1) return
    this.editorBlocks[editorBlockIndex].pureFile = {}
    this.editorBlocks[editorBlockIndex].fileSource = {}
    this.clearMediaInput(`editor-video-input-${id}`)
  }
  @action changeVideoControls(id, value) {
    const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorBlockIndex == -1) return
    if (this.editorBlocks[editorBlockIndex].fileSource)
      this.editorBlocks[editorBlockIndex].fileSource.isStopVideo = value
  }

  // image block
  @action verifyImageFileInput(id, files: File[]) {
    let message = { status: true, error: '' } as any

    const checkEditorBlocks = this.editorBlocks.filter(
      (item) => item.type === 'image-block' && item.id !== id && item.fileSources?.length
    )
    const checkFileSources = flattenDeep(map(checkEditorBlocks, 'fileSources'))
    const previousImagesTotalSize = sumBy(checkFileSources, (item) => item?.sizeByByte || 0)
    const currentImagesTotalSize = sumBy(files, (item) => item.size)
    const totalSize = previousImagesTotalSize + currentImagesTotalSize
    if (totalSize > MAX_SIZE_IMAGE_UPLOAD_BY_BYTE)
      message = {
        status: false,
        error: `Uploaded photos must not exceed ${MAX_SIZE_IMAGE_UPLOAD_BY_MB}MB`,
      }
    return message
  }
  @asyncAction *changeImageBlockFileInputs(files: File[], id) {
    const editorIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorIndex === -1) return

    const verifiedMessage = this.verifyImageFileInput(id, files)
    if (!verifiedMessage.status) {
      snackController.error(verifiedMessage.error)
      this.clearMediaInput(`editor-image-input-${id}`)
      return
    }

    const fileSources: PostMediaSource[] = []
    const pureFiles: PostMediaFile[] = []
    for (let i = 0; i <= files.length - 1; i++) {
      const file = files[i]
      const type = file.type.includes('video') ? 'video' : 'image'
      const fileURL = URL.createObjectURL(file)
      const durationBySecond = yield this.getFileDurationBySecond(fileURL, type)
      const id: any = uuidv4()
      fileSources.push({
        id: id,
        type,
        source: fileURL,
        name: file.name,
        sizeByByte: file.size,
        sizeByMb: Math.round(file.size / MB_TO_BYTE),
        isExceededSize: file.size > MAX_SIZE_IMAGE_UPLOAD_BY_BYTE,
        isStopVideo: true,
        durationBySecond,
      })
      pureFiles.push({ id, file })
    }
    this.editorBlocks[editorIndex]['fileSources'] = chunk(fileSources, 2)
    this.editorBlocks[editorIndex]['pureFiles'] = chunk(pureFiles, 2)
  }
  @action removeImageBlockFileInput(id, row, itemIndex) {
    const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === id)
    if (editorBlockIndex == -1) return
    const pureFileRow = this.editorBlocks[editorBlockIndex].pureFiles[row]
    const sourceFileRow = this.editorBlocks[editorBlockIndex].fileSources[row]
    pureFileRow.splice(itemIndex, 1)
    sourceFileRow.splice(itemIndex, 1)
    if (pureFileRow.length) {
      this.editorBlocks[editorBlockIndex].pureFiles[row] = pureFileRow
      this.editorBlocks[editorBlockIndex].fileSources[row] = sourceFileRow
    } else {
      this.editorBlocks[editorBlockIndex].pureFiles.splice(row, 1)
      this.editorBlocks[editorBlockIndex].fileSources.splice(row, 1)
    }
    this.clearMediaInput(`editor-image-input-${id}`)
  }

  // tag
  @asyncAction *fetchTags() {
    const tags = yield apiService.tags.find({})
    this.tagList = tags
    this.tagContentList = tags.map((tag) => tag.content.toLowerCase())
  }
  @action validTagInput(event: any) {
    if (event.key === ' ' || event.key === '#') {
      event.preventDefault()
    }
  }
  @action onTagSelected() {
    this.tagTextSearch = ''
  }
  @asyncAction *createTags(newTags: string[]) {
    try {
      const promises: Promise<any>[] = []
      newTags.map((tag) => {
        promises.push(apiService.tags.create({ content: tag.toLowerCase() }))
      })
      const res = Promise.all(promises)
      return res
    } catch (error) {
      snackController.commonError(error)
    }
  }
  @action regexHashTag(content = '') {
    // remove link (<a>#abc</a> => #abc) from content
    const validContentRegex = /<a+(\shref="\/search\/forum)(.*?)>?.<\/a>/gi
    const hashtagRegex = /\B(?:#|＃)((?![\p{N}ـ_]+(?:$|\b|\s))(?:[\p{L}\p{M}\p{N}_]{1,60}))/gu
    const validRegex = /(?=target="_blank">).+(?=<\/a>)/gi

    const validContent = content.replace(validContentRegex, function (value) {
      const regexTags = value.match(validRegex)
      return regexTags ? regexTags[0] : ''
    })
    // make lowercase tags
    const makeLowerCaseTag = validContent.replace(hashtagRegex, function (value) {
      return value.toLowerCase()
    })

    // statistic tag list from content
    const tagInContents = makeLowerCaseTag.match(hashtagRegex) || ([] as string[])

    // add link for tag
    const htmlContentWithLink = makeLowerCaseTag.replace(hashtagRegex, function (value) {
      return (
        `<a href='/search/forum?text=` + value.substring(1) + "' class='hashtag_link' target='_blank'>" + value + '</a>'
      )
    })
    return {
      htmlContentWithLink,
      tagInContents: tagInContents,
    }
  }

  // link
  @asyncAction *fetchOpenGraphInfo(url?: string) {
    if (!url) return
    const graphInfo = yield apiService.posts.getOpenGraphInfo(url)
    return graphInfo
  }
  @action.bound resetInsertOgInfo(blockId) {
    const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === blockId)
    if (editorBlockIndex == -1) return
    this.editorBlocks[editorBlockIndex]['insertOgInfo'] = undefined
  }
  @asyncAction *insertUrl(urlLink, blockId) {
    try {
      if (this.linkInserting) return

      const editorBlockIndex = this.editorBlocks.findIndex((item) => item.id === blockId)
      if (editorBlockIndex == -1) return

      this.linkInserting = true
      const insertOgInfo = yield this.fetchOpenGraphInfo(urlLink)
      this.editorBlocks[editorBlockIndex]['insertOgInfo'] = insertOgInfo
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.linkInserting = false
    }
  }

  // tag location
  @action changeTagLocationDialog(value) {
    if (value) {
      this.tagLocationDialog = true
      this.searchLocationResult = []
    } else {
      this.tagLocationDialog = false
    }
  }
  @action setTagLocation(location, mapLocation) {
    if (location && mapLocation)
      this.tagLocation = {
        location: location,
        sourceImage: mapLocation.url,
      }
  }
  @action changeSearchLocationResult(locations) {
    const validLocations = locations?.filter((item) => !!item.place_id)
    const res =
      validLocations?.map((item) => {
        const main_text = item?.structured_formatting?.main_text
        return {
          mainText: main_text,
          secondaryText: item?.structured_formatting?.secondary_text || main_text,
          placeId: item.place_id,
        }
      }) || []
    this.searchLocationResult = res
  }
  @asyncAction *changeTagLocation(location, index) {
    try {
      if (this.fetchPlaceDetailLoading) return
      this.fetchPlaceDetailLoading = true
      this.placeLoadingIndex = index
      const res = yield apiService.posts.fetchPlaceDetail({
        fields: ['name', 'geometry'],
        placeId: location.placeId,
      })
      this.tagLocation = {
        location,
        sourceImage: `data:image/png;base64, ${res}`,
        base64Source: res,
      }
      this.addLocationBlock()
      this.changeTagLocationDialog(false)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.fetchPlaceDetailLoading = false
    }
  }
  @action addLocationBlock() {
    this.removeLocationBlock()
    this.editorBlocks.splice(this.editorBlocks.length, 0, {
      type: 'location-block',
      id: this.editorBlockCount,
      options: { bounds: `#quill-container-${this.editorBlockCount}` },
      tagLocation: this.tagLocation,
      letterCount: 0,
    })
    this.editorBlockCount++
  }
  @action.bound removeLocationBlock(forceClearData = false) {
    const lastIndex = this.editorBlocks.length - 1
    if (this.editorBlocks[lastIndex].type === 'location-block') this.editorBlocks.splice(lastIndex, 1)
    if (forceClearData) this.tagLocation = undefined
  }

  @computed get itemsDisplayed() {
    if (!this.tagTextSearch) {
      return this.tagsSelected
    }
    const response = this.tagContentList.filter((e) => e.includes(this.tagTextSearch.toLowerCase()))
    return this.tagsSelected.concat(response)
  }
}
