import { localData } from "@/stores/local-data";
import { ApiRouteType } from "../types";

export class DaoRequestHandler<T> {
    constructor(private axios: any, private route: ApiRouteType) {}
  
    async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
      const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
      params = { ...settingDefault, ...settings, ...(params ?? {}) }
      const res = await this.axios.get(this.route, { params })
      const lst = res.data
      return lst
    }
  
    async markRequestConfirm(requestId: string) {
      const res = await this.axios.post(
        `/dao-requests/${requestId}/mark-confirm`,
        {},
        {
          headers: {
            ...this.axios.defaults.headers,
            Authorization: `Bearer ${localData.getAccessToken()}`,
          },
        }
      )
      return res.data
    }
  }