import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommentModel, OgInfo, PollModel, PostContentBlock, PostModel } from '@/models/post-model'
import { RecruitmentApplyModel } from '@/models/recruitment-apply-model'
import { RecruitmentInfoModel } from '@/models/RecruitmentInfoModel'
import { apiService } from '@/services/api-services'
import { flatten, isEmpty, isEqual, isNil, map, omitBy, orderBy, reduce, reverse, sortBy, sum, uniqueId } from 'lodash'
import { action, computed, IReactionDisposer, observable, reaction, toJS } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ApplicantStore } from './applicant-store'
import { dispatcher, FollowingProfile, FollowTypeEnum } from './dispatcher'
import { postMediasDetailStore } from './post-medias-detail-store'
import { postShareStore } from './post-share'
import { walletStore } from './wallet-store'

export class PostStore {
  @observable post: PostModel
  @observable commentInput = ''
  @observable shortPlainContent = ''
  @observable mentionUsers: string[] = []
  @observable allComments: CommentModel[] = []
  @observable ogInfo?: OgInfo = undefined
  @observable isEnableComment = false

  @observable contentBlocks: PostContentBlock[] = []
  @observable fetchingComment = false
  @observable currentVote: number | undefined = undefined
  @observable poll?: PollModel = undefined
  @observable showTranslate = false
  @observable translatedTitle = ''
  @observable edittingComment?: CommentModel = undefined
  private _unsubcrible = new Subject()
  @observable isPinded = false
  @observable pinLoading = false
  @observable applicants: ApplicantStore[] = []

  constructor(post: PostModel) {
    this.post = post
    this.currentVote = this.post?.yourVote?.voteOption
    this.poll = this.post?.poll
    this.applicants = (this.post?.applicants || []).map((applicant) => new ApplicantStore(applicant))

    this.fetchContent()
    this.allComments = this.post.comments || []

    let lastEditing: CommentModel | undefined = undefined
    reaction(
      () => this.edittingComment,
      (v) => {
        if (lastEditing) {
          lastEditing.editing = false
        }
        lastEditing = v
      }
    )

    dispatcher.$profileChanged.pipe(takeUntil(this._unsubcrible)).subscribe((profile) => {
      this.syncFollowProfile(profile)
    })
  }

  @action togglePinMode() {
    this.isPinded = !this.isPinded
  }

  @action syncFollowProfile(profile: FollowingProfile) {
    if (profile.type === FollowTypeEnum.user && this.post?.profile?.id === profile.to) {
      this.post = { ...this.post, isFollowing: profile.followState, loading: false }
    }
  }

  @action.bound setMentionUsers(users) {
    if (!isEqual(sortBy(toJS(this.mentionUsers)), sortBy(users))) {
      this.mentionUsers = [...users]
    }
  }

  destroy() {
    this._unsubcrible.next()
    this._unsubcrible.complete()
  }

  @asyncAction *handleLike() {
    // if (!walletStore.verifyUserAction()) return
    try {
      if (!this.post.isLike) {
        this.post.isLike = !this.post.isLike
        this.post.likeCount = (this.post.likeCount as number) + 1
        yield apiService.posts.like({ post: this.post })
      } else {
        this.post.isLike = !this.post.isLike
        this.post.likeCount = (this.post.likeCount as number) - 1
        yield apiService.posts.unlike({ post: this.post })
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action changeEnableComment() {
    // if (!walletStore.verifyUserAction()) return
    this.isEnableComment = !this.isEnableComment
    if (this.isEnableComment) this.fetchAllComments()
  }

  @asyncAction *fetchAllComments() {
    try {
      if (this.fetchingComment) return
      this.fetchingComment = true
      this.allComments = yield apiService.comments.find({ post: this.post.id })
      const ec = this.edittingComment
      if (ec && !this.allComments.find((c) => c.id === ec.id)) {
        this.edittingComment = undefined
      }
    } catch (error) {
      this.fetchingComment = false
    }
  }

  @asyncAction *requestEditComment(comment: CommentModel) {
    this.edittingComment = comment
  }

  @action.bound cancelComment() {
    this.commentInput = ''
    this.edittingComment = undefined
    this.mentionUsers = []
  }

  @asyncAction *handleComment() {
    // if (!walletStore.verifyUserAction()) return
    try {
      if (this.commentInput) {
        const edittingComment = this.edittingComment
        if (edittingComment) {
          const inputTmp = this.commentInput
          this.commentInput = ''
          const edittedComment = yield apiService.comments.update(edittingComment.id, {
            content: inputTmp,
            mentionUsers: this.mentionUsers,
            shortPlainContent: this.shortPlainContent,
          })
          this.allComments = this.allComments.map((x) => (x.id === edittedComment.id ? edittedComment : x))
          this.edittingComment = undefined
        } else {
          const profile = walletStore.userInfo?.profile
          const fakeId = uniqueId()
          let newComment = {
            id: fakeId,
            content: this.commentInput,
            owner: profile,
            createdAt: moment().toISOString(),
            isTemporary: true,
          }
          this.allComments = [...this.allComments, newComment]
          const inputTmp = this.commentInput
          this.commentInput = ''
          if (profile && inputTmp) {
            newComment = yield apiService.comments.createComment({
              post: this.post,
              content: inputTmp,
              mentionUsers: this.mentionUsers,
              shortPlainContent: this.shortPlainContent,
            })
            this.allComments = [...this.allComments, newComment].filter((x) => x.id !== fakeId)
            this.post.commentCount = (this.post.commentCount as number) + 1
          }
        }
        this.commentInput = ''
        this.mentionUsers = []
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *handleReplyTo(comment: CommentModel) {
    // if (!walletStore.verifyUserAction()) return
    try {
      if (comment.replyingText) {
        const profile = walletStore.userInfo?.profile

        const fakeId = uniqueId()
        let newComment = {
          id: fakeId,
          content: comment.replyingText,
          owner: profile,
          replyTo: { id: comment.id },
          createdAt: moment().toISOString(),
          isTemporary: true,
        }

        this.allComments = [newComment, ...this.allComments]
        comment.totalReply = (comment.totalReply || 0) + 1

        const inputTmp = comment.replyingText
        comment.replyingText = ''
        if (profile && inputTmp) {
          newComment = yield apiService.comments.createComment({
            post: this.post,
            content: inputTmp,
            mentionUsers: comment.replyingMentionUsers,
            replyTo: comment.id,
            shortPlainContent: this.shortPlainContent,
          })
          this.allComments = [newComment, ...this.allComments].filter((x) => x.id !== fakeId)
          comment.replyingMentionUsers = []
          comment.replying = false
          this.post.commentCount = (this.post.commentCount as number) + 1
        }
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action handleSharePost() {
    postShareStore.handleSharePost(this.post)
  }

  @asyncAction *fetchOpenGraphInfo(url: string) {
    this.ogInfo = yield apiService.posts.getOpenGraphInfo(url)
  }

  @asyncAction *followUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.followUser({ follow: item.profile.id })
      item.isFollowing = true
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @asyncAction *unFollowUser(item: any) {
    // if (!walletStore.verifyUserAction()) return
    try {
      item.loading = true
      yield apiService.userFollows.unFollowUser({ follow: item.profile.id })
      item.isFollowing = false
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.loading = false
    }
  }

  @action openEmbedLink() {
    window.open(this.firstOpenGraphInfo?.requestUrl, '_blank')
  }

  @action changeOpenPostMediasDetail(forceHide = false, value, index = -1) {
    if (forceHide && value) return
    if (value) {
      postMediasDetailStore.init(this)
      postMediasDetailStore.changeOpenPostMediasDetail(forceHide, value, index)
    } else postMediasDetailStore.changeOpenPostMediasDetail(forceHide, value)
  }

  @asyncAction *deleteComment(id) {
    try {
      const allComments = this.allComments.filter((x) => !!x.replyTo)
      const comment2Ids = map(
        allComments.filter((x) => x.replyTo.id === id),
        'id'
      ).filter((x) => x != undefined)
      const getComment3Ids = [] as any
      for (let i = 0; i < comment2Ids.length; i++) {
        getComment3Ids.push(
          map(
            allComments.filter((x) => x.replyTo.id === comment2Ids[i]),
            'id'
          )
        )
      }
      const commets3Ids = flatten(getComment3Ids)
      const removedCommentIds = [id, ...comment2Ids, ...commets3Ids]
      this.allComments = this.allComments.filter((x) => !removedCommentIds.includes(x.id))
      const commentCount = (this.post.commentCount || 0) - removedCommentIds.length
      this.post.commentCount = commentCount < 0 ? 0 : commentCount
      yield apiService.comments.deleteComment(id)
    } catch (e) {
      console.log('Err', e)
      snackController.commonError(e)
    }
  }

  @asyncAction *handleVotePoll(index) {
    // if (!walletStore.verifyUserAction()) return
    try {
      this.currentVote = index
      yield apiService.votes.vote({
        pollId: this.poll?.id,
        voteOption: index,
      })
      this.poll = yield apiService.polls.findOne(this.poll?.id)
    } catch (e) {
      console.log('vote error: ', e)
    }
  }

  @asyncAction *handleBookmark() {
    try {
      if (!this.post.isBookmarked) {
        this.post.isBookmarked = !this.post.isBookmarked
        yield apiService.bookmarks.createBookmark({ postId: this.post?.id as string })
      } else {
        this.post.isBookmarked = !this.post.isBookmarked
        yield apiService.bookmarks.removeBookmark({ postId: this.post?.id as string })
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *postViewIncrement() {
    if (this.post.id) yield apiService.posts.postViewIncrement(this.post.id)
  }

  @asyncAction *linkViewIncrement(link: string) {
    if (this.post.id && link) yield apiService.links.increaseLinkView({ postId: this.post.id, link })
  }

  @computed get medias() {
    return this.post?.medias || []
  }

  @computed get imageLinks() {
    const images = this.medias?.filter((item) => item?.mime?.includes('image'))
    return map(images, 'url')
  }
  @computed get videoLinks() {
    const videos = this.medias?.filter((item) => item?.mime?.includes('video'))
    return map(videos, 'url')
  }

  @computed get link() {
    return this.post?.link
  }

  @computed get content() {
    return !isEmpty(this.post.content) ? this.post.content : ''
  }

  // FIXED ME
  @asyncAction *fetchContent() {
    this.contentBlocks = this.post?.data?.content || []
    const linkIndexs = [] as any
    const getLPromises = [] as any
    this.contentBlocks?.map((item, index) => {
      if (item.type === 'link' && item.link) {
        linkIndexs.push(index)
        getLPromises.push(apiService.posts.getOpenGraphInfo(item.link))
      }
    })
    if (!linkIndexs.length) return
    const res = yield Promise.all(getLPromises)
    res.map((item, index) => {
      this.contentBlocks[linkIndexs[index]]['ogInfo'] = item
    })
  }

  @asyncAction *translate() {
    try {
      this.showTranslate = true
      const blockTextIndexs = [] as any
      const blockTexts = [this.title] as any
      this.contentBlocks.map((item, index) => {
        if (item.type === 'text') {
          blockTextIndexs.push(index)
          blockTexts.push(item.htmlContent)
        }
        return item
      })
      const [res] = yield apiService.posts.translate({ text: blockTexts, target: walletStore.defaultLanguage })
      const [title, ...translatedBlockTexts] = res
      blockTextIndexs.map((item, index) => {
        this.contentBlocks[item].translatedContent = translatedBlockTexts[index]
      })
      this.translatedTitle = title
    } catch (e) {
      this.showTranslate = false
    }
  }

  @computed get title() {
    return this.post?.title
  }

  // @computed get mediaContents() {
  //   const mediaContentBlocks = this.contentBlocks?.filter((item) => item.type === 'image' || item.type === 'video')
  //   const mediaFiles = map(mediaContentBlocks, 'file')
  //   return flatMapDeep(mediaFiles)
  // }

  @computed get contentMedias() {
    const medias = this.post?.medias || []
    const formatMedias = [] as any
    medias.map((item) => {
      formatMedias[item._id] = item
    })
    return formatMedias
  }

  @computed get concatedTagContent() {
    const tagContents = map(this.post?.tags, 'content') || []
    const res = reduce(tagContents, (previousVal, currentVal) => previousVal + `${currentVal}  `, '')
    return res
  }
  @computed get isPostOwner() {
    return this.post?.profile?._id === walletStore.userProfile?._id
  }

  @computed get ownerProfileId() {
    return walletStore.userProfile?._id
  }

  @computed get commentsOrderByLatest() {
    return this.allComments.reverse()
  }
  @computed get firstMediaFile() {
    let mediaFile = undefined as any
    for (let i = 0; i < this.contentBlocks.length; i++) {
      const block = this.contentBlocks[i]
      if (block.type === 'image' && block.files[0][0]) {
        const id = block.files[0][0]
        mediaFile = { media: this.contentMedias[id], type: 'image' }
        break
      } else if (block.type === 'video' && block.files[0]) {
        const id = block.files[0]
        mediaFile = { media: this.contentMedias[id], type: 'video' }
        break
      }
    }
    return mediaFile
  }
  @computed get firstImage() {
    let mediaFile = undefined as any
    for (let i = 0; i < this.contentBlocks.length; i++) {
      const block = this.contentBlocks[i]
      if (block.type === 'image' && block.files[0][0]) {
        const id = block.files[0][0]
        mediaFile = this.contentMedias[id]
        break
      }
    }
    return mediaFile
  }
  @computed get firstOpenGraphInfo() {
    let ogInfo = undefined as any
    for (let i = 0; i < this.contentBlocks.length; i++) {
      const block = this.contentBlocks[i]
      if (block.type === 'link' && block['ogInfo']) {
        ogInfo = block['ogInfo']
        break
      }
    }
    return ogInfo
  }
  @computed get firstTextBlock() {
    // let htmlContent = '' as any
    // for (let i = 0; i < this.contentBlocks.length; i++) {
    //   const block = this.contentBlocks[i]
    //   if (block.type === 'text' && block.rawContent) {
    //     htmlContent = block.htmlContent
    //   }
    // }
    const content = this.post?.data?.content || []
    for (let i = 0; i < content.length; i++) {
      if (content[i].type === 'text' && content[i].rawContent) {
        return content[i].htmlContent
      }
    }
    return ''
  }
  @computed get firstRawTextBlock() {
    // let htmlContent = '' as any
    // for (let i = 0; i < this.contentBlocks.length; i++) {
    //   const block = this.contentBlocks[i]
    //   if (block.type === 'text' && block.rawContent) {
    //     htmlContent = block.htmlContent
    //   }
    // }
    const content = this.post?.data?.content || []
    for (let i = 0; i < content.length; i++) {
      if (content[i].type === 'text' && content[i].rawContent) {
        return content[i].rawContent
      }
    }
    return ''
  }

  @computed get openGraphLinkImage() {
    return this.firstOpenGraphInfo?.ogImage?.url
  }

  @computed get pollOptions() {
    return this.poll?.data?.options
  }
  @computed get yourVote() {
    return this.post?.yourVote
  }
  @computed get pollDuration() {
    return this.poll?.data?.durationTime
  }
  @computed get totalVotes() {
    return this.poll?.totalVotes
  }
  @computed get location() {
    return this.post?.location
  }
  @computed get mapLocation() {
    return this.post?.mapLocation
  }
  @computed get dao() {
    return this.post?.dao
  }
  @computed get isPollEnded() {
    const startTime = this.poll?.data?.startTime
    if (!startTime || !this.pollDuration) return false

    const days = this.pollDuration?.days || 0
    const hours = this.pollDuration?.hours || 0
    const minutes = this.pollDuration?.minutes || 0
    const pollDuration = moment.duration({
      days: +days,
      hours: +hours,
      minutes: +minutes,
    })
    return moment(startTime).add(pollDuration).isBefore(moment())
  }

  @computed get firstMedia() {
    return (this.post?.medias || [])[0]
  }

  @computed get firstMediaRepost() {
    return (this.post?.rePost?.medias || [])[0]
  }

  @computed get firstTextBlockRepost() {
    let htmlContent = '' as any
    const content = this.post?.rePost?.data?.content || []
    for (let i = 0; i < content.length; i++) {
      if (content[i].type === 'text' && content[i].rawContent) {
        htmlContent = content[i].htmlContent
      }
    }
    return htmlContent
  }

  @computed get repostedPost() {
    return this.post?.rePost
  }

  @computed get repostTitle() {
    return this.post?.rePost?.title
  }

  @computed get repostProfile() {
    return this.post?.rePost?.profile
  }
  @computed get postProfile() {
    return this.post?.profile
  }

  @computed get postProfilePopoAvatars() {
    return this.postProfile?.popo_avatars
  }

  @computed get repostCreatedAt() {
    return this.post?.rePost?.createdAt
  }

  @computed get firstMediaRepostIsImage() {
    return this.firstMediaRepost?.mime?.includes('image')
  }

  @computed get firstMediaRepostIsVideo() {
    return this.firstMediaRepost?.mime?.includes('video')
  }

  @computed get reports() {
    return reverse(this.post?.reports || [])
  }

  @computed get isBlind() {
    return this.post.isBlind
  }

  @computed get lastReportedTime() {
    return this.post?.lastReportedTime || ''
  }

  @action getRawComment(el: Node) {
    el.childNodes.forEach((e, i) => {
      if ((e as any).attributes?.href?.nodeValue?.includes('/profile/')) {
        ;(e as any).innerText = `@${(e as any).innerText}`.toUpperCase()
      }
    })
    return (el as any).innerText || ''
  }

  @asyncAction *translateComment(name, id) {
    const index = this.allComments.findIndex((item) => item.id == id)
    if (index == -1) return
    const comment = this.allComments[index]
    if (comment.translateLoading) return
    const commentEle = document.getElementById(name + '-' + id)
    if (!commentEle) return

    try {
      this.allComments[index].translateLoading = true

      if (comment.isTranslated) {
        this.allComments[index].showTranslatedContent = !comment.showTranslatedContent
        return
      }
      const content = this.getRawComment(commentEle.cloneNode(true))
      const [translatedContent] = yield apiService.posts.translate({
        target: { from: (comment.language || 'en').substring(0, 2), to: walletStore.defaultLanguage },
        text: content,
      })
      this.allComments[index].translatedContent = translatedContent
      this.allComments[index].isTranslated = true
      this.allComments[index].showTranslatedContent = true
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.allComments[index].translateLoading = false
    }
  }

  @computed get rootComments() {
    return orderBy(this.allComments, ['createdAt'], ['desc'])
      .filter((x) => !x.replyTo)
      .map((x) => {
        const replyComments = this.allComments
          .filter((y) => y.replyTo && y.replyTo.id === x.id)
          .map((rc) => {
            const reps = this.allComments.filter((y) => y.replyTo && y.replyTo.id === rc.id)
            return { comment: rc, replyComments: orderBy(reps, (rc) => rc.createdAt) }
          })
        // if (x.totalReply && x.totalReply > 0) {
        //   console.log(replyComments, this.allComments.filter(x => x.replyTo))
        // }
        return { comment: x, replyComments: orderBy(replyComments, (rc) => rc.comment.createdAt) }
      })
  }

  @computed get recruitment_info() {
    return this.post?.recruitment_info
  }
  @computed get rctType() {
    return this.recruitment_info?.type
  }

  @computed get rctTitle() {
    return this.recruitment_info?.title
  }
  @computed get rctAreaName() {
    return this.recruitment_info?.data?.recruitmentArea?.name
  }
  @computed get rctNoInfos() {
    const teamInfo = this.recruitment_info?.data?.teamInfo
    const jobInfoData = this.recruitment_info?.data?.jobInfoData
    return teamInfo?.length ? teamInfo : jobInfoData || []
  }
  @computed get rctTotalApplicants() {
    return sum(map(this.rctNoInfos, 'recruitNumber'))
  }
  @computed get createdAt() {
    return this.post.createdAt
  }
  @computed get rctJdFile() {
    return this.recruitment_info?.jdFile
  }

  @computed get rctSocials() {
    const data = this.recruitment_info?.data
    return omitBy(
      {
        facebook: data.facebook,
        twitter: data.twitter,
        youtube: data.youtube,
        telegram: data.telegram,
        linkedin: data.linkedin,
      },
      isNil
    )
  }

  @computed get rctDueDate() {
    return this.recruitment_info?.dueDate
  }

  @computed get canTranslate() {
    return !this.showTranslate && (this.post.language || 'en' != walletStore.defaultLanguage)
  }

  @computed get displayedApplicants() {
    return this.applicants.slice(0, 4)
  }

  @computed get pendingApplicants() {
    return this.applicants.filter((a) => a.model.status === 'pending')
  }

  @computed get myApply() {
    return this.post.myApply
  }

  @computed get isRecruitment() {
    return this.post?.type === 'recruitment'
  }

  @computed get isRctClosed() {
    return this.rctDueDate && moment().isAfter(this.rctDueDate)
  }

  @computed get rctHtmlContent() {
    return this.post?.data?.rctHtmlContent || '<p></p>'
  }

  @computed get rctJobInfo() {
    return this.recruitment_info?.data?.jobInfoData
  }

  @computed get isFindMyTeam() {
    return this.recruitment_info?.type === 'find_my_team'
  }
  @computed get isFindJob() {
    return this.recruitment_info?.type === 'find_job'
  }

  @computed get rctJobNoInfo() {
    const data = this.recruitment_info?.data
    const hobbyTypeName = data?.hobbyData?.name
    return hobbyTypeName
      ? {
          name: hobbyTypeName,
          recruitNumber: this.recruitment_info?.recruitNumber,
        }
      : undefined
  }

  @computed get rctEmail() {
    return this.recruitment_info?.data?.emailAddress
  }
}
