import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import { ApiRouteType } from '../types'

export class FractalUserHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
    }
    const result = res.data
    return result
  }

  async fractalKycOauth(code): Promise<T> {
    const res = await this.axios.post(
      `/${this.route}/fractal-kyc-oauth`,
      { code },
      {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      }
    )
    return res.data
  }
}
