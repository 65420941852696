import { localData } from '@/stores/local-data'
import { ApiRouteType } from '../types'

export class EarningActionHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async getEarnDataSummary<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    if(localData.getAccessToken()) {
      const res = await this.axios.get(`/get-earn-data-summary`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      })
      return res.data
    } else {
      return []
    }
  }

  async getEarningActions<T>(
    filter?: {page: number, itemPerPage: number},
  ): Promise<T[]> {

    const res = await this.axios.get(`/get-earning-actions?page=${filter?.page || 1}&itemPerPage=${filter?.itemPerPage || 10}`, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async getChartData<T>(
    filter?: '7d' | '30d',
  ): Promise<T[]> {
    const res = await this.axios.get(`/get-chart-data?filter=${filter}`, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

}
