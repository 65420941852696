import { localData } from "@/stores/local-data"
import { ApiRouteType } from "../types"

export class BlockHandler<T> {
    constructor(private axios: any, private route: ApiRouteType) {}
    async findOne<T>(id: any): Promise<T> {
      let res: any
      if (id) {
        res = await this.axios.get(`${this.route}/${id}`)
      } else {
        res = await this.axios.get(`${this.route}`)
      }
      const result = res.data
      return result
    }
  
    async blockPostOwner(id): Promise<T> {
      const res = await this.axios.post(
        `block-user`,
        { blocked: id },
        {
          headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
        }
      )
      return res.data
    }
  }