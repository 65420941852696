import { appProvider } from '@/app-provider'
import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import { reverse } from 'lodash'
import { when } from 'mobx'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import qs from 'qs'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: '/community' },
  {
    path: '/referral',
    meta: {
      auth: true,
    },
    name: 'Referral',
    component: () => import('@/modules/referral/page/referral-page.vue'),
  },
  {
    path: '/nft/:id',
    component: () => import('@/modules/reward/pages/nft-detail-page.vue'),
    name: 'Nft Detail Page',
    meta: {
      auth: true,
    },
  },
  {
    path: '/earn',
    meta: {
      auth: true,
    },
    name: 'Reward',
    component: () => import('@/modules/reward/pages/earn-layout.vue'),
    children: [
      {
        path: '/',
        redirect: 'nfts',
      },
      {
        path: 'nfts',
        component: () => import('@/modules/reward/pages/nft-page.vue'),
        name: 'Nft Page',
        meta: {
          auth: true,
        },
      },
      {
        path: 'reward',
        component: () => import('@/modules/reward/pages/reward-page.vue'),
        name: 'Reward',
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login Socials',
    component: () => import('@/modules/login-socials/page/login-social-page.vue'),
    meta: {
      appbar: false,
      jwtRedirect: '/',
    },
  },
  {
    path: '/google-callback',
    name: 'Google callback',
    component: () => import('@/modules/login-socials/page/google-callback-page.vue'),
    meta: {
      appbar: false,
      socialCallback: true,
    },
  },
  {
    path: '/apple-callback',
    name: 'Apple callback',
    component: () => import('@/modules/login-socials/page/apple-callback-page.vue'),
    meta: {
      appbar: false,
      socialCallback: true,
    },
  },

  {
    path: '/community',
    name: 'Community',
    component: () => import('@/modules/community/pages/community.vue'),
    children: [
      {
        path: '/',
        redirect: 'my-page',
      },
      {
        path: 'trending',
        name: 'Trending-No-Auth',
        component: () => import('@/modules/community/home/pages/trending-no-auth.vue'),
        meta: {
          loggedInRedirect: '/',
        },
      },
      {
        path: 'my-page',
        name: 'My Page',
        component: () => import('@/modules/community/home/pages/my-page.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'review',
        name: 'Review',
        component: () => import('@/modules/community/home/pages/review.vue'),
      },
      {
        path: 'category/:id',
        name: 'Post List By Category',
        component: () => import('@/modules/community/home/pages/post-list-by-category-page.vue'),
      },
      {
        path: 'user-to-follow',
        name: 'User to follow',
        component: () => import('@/modules/community/home/pages/user-to-follow.vue'),
      },
      {
        path: 'dao-to-join',
        name: 'Dao to join',
        component: () => import('@/modules/community/home/pages/dao-to-join.vue'),
      },
      {
        path: 'discovery',
        name: 'Discovery',
        component: () => import('@/modules/community/home/pages/discovery.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'tag/:tagId/posts',
        name: 'Tag posts',
        component: () => import('@/modules/community/home/pages/trending.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: 'post/:postid',
        name: 'Post-Detail',
        component: () => import('@/modules/community/home/pages/post-detail.vue'),
        meta: {
          auth: false,
        },
      },
    ],
  },
  {
    path: '/community-organize',
    name: 'Community organize',
    component: () => import('@/modules/community-organize/pages/community-organize.vue'),
    meta: {
      auth: true,
      verifyUserAction: true,
    },
    children: [
      {
        path: 'dao/create',
        name: 'Create community',
        component: () => import('@/modules/community-organize/pages/create-community.vue'),
      },
      {
        path: 'dao-detail/:id',
        name: 'Community Detail',
        component: () => import('@/modules/community-organize/pages/community-detail.vue'),
      },
      {
        path: 'dao-detail/:id/setting',
        name: 'Community Detail Setting',
        component: () => import('@/modules/community-organize/pages/dao-detail-setting.vue'),
        children: [
          {
            path: '/',
            redirect: 'dao-members',
          },
          {
            path: 'review-member-requests',
            name: 'Review Member Request',
            component: () =>
              import('@/modules/community-organize/components/dao-detail-setting/pages/review--member-requests.vue'),
          },
          {
            path: 'review-reported-posts',
            name: 'Review Reported Posts',
            component: () =>
              import('@/modules/community-organize/components/dao-detail-setting/pages/review--reported-posts.vue'),
          },
          {
            path: 'dao-members',
            name: 'DAO Members',
            component: () =>
              import('@/modules/community-organize/components/dao-detail-setting/pages/dao-management--members.vue'),
          },
          {
            path: 'dao-rules',
            name: 'DAO Rules',
            component: () =>
              import('@/modules/community-organize/components/dao-detail-setting/pages/dao-management--rules.vue'),
          },
          {
            path: 'dao-settings',
            name: 'DAO Settings',
            component: () =>
              import('@/modules/community-organize/components/dao-detail-setting/pages/dao-management--settings.vue'),
          },

          {
            path: 'dao-faqs',
            name: 'DAO FAQ',
            component: () =>
              import('@/modules/community-organize/components/dao-detail-setting/pages/dao-management--faqs.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/recruitment',
    name: 'Recruitment',
    redirect: '/recruitment/posts',
    component: () => import('@/modules/recruitment/daos/pages/rct-dao-page.vue'),
    children: [
      {
        name: 'Recruitment Posted Job',
        path: 'posted-jobs',
        component: () => import('@/modules/recruitment/daos/components/posted-job/posted-job-page.vue'),
      },
      {
        name: 'My Apply',
        path: 'my-apply',
        component: () => import('@/modules/recruitment/daos/pages/rct-my-apply-page.vue'),
      },
      {
        name: 'Recruitment Posts',
        path: 'posts',
        component: () => import('@/modules/recruitment/daos/pages/posts-page.vue'),
      },
    ],
    meta: {
      auth: true,
    },
  },
  {
    name: 'Recruitment post detail',
    path: '/recruitment/posts/:id',
    component: () => import('@/modules/recruitment/daos/pages/post-detail-page.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/recruitment/posted-jobs/:id',
    component: () => import('@/modules/recruitment/daos/pages/posted-job-detail-page.vue'),
    meta: {
      auth: true,
    },
  },

  {
    path: '/recruitment/applicants/:id',
    component: () => import('@/modules/recruitment/daos/pages/rct-applicant-page.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/profile/:userId',
    name: 'Profile',
    component: () => import('@/modules/profile/pages/profile.vue'),
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/',
        redirect: 'summary',
      },
      {
        path: 'summary',
        name: 'Summary',
        component: () => import('@/modules/profile/pages/summary.vue'),
      },
      {
        path: 'activity',
        name: 'Activity',
        component: () => import('@/modules/profile/pages/activity.vue'),
        children: [
          {
            path: '/',
            redirect: 'posts',
          },
          {
            path: 'posts',
            name: 'Post',
            component: () => import('@/modules/profile/activity/pages/posts.vue'),
          },
          {
            path: 'likes',
            name: 'Like',
            component: () => import('@/modules/profile/activity/pages/likes.vue'),
          },
          {
            path: 'draft',
            name: 'Draft',
            component: () => import('@/modules/profile/activity/pages/draft.vue'),
          },
          {
            path: 'bookmark',
            name: 'Bookmark',
            component: () => import('@/modules/profile/activity/pages/bookmark.vue'),
          },
          {
            path: 'comments',
            name: 'Comments',
            component: () => import('@/modules/profile/activity/pages/comments.vue'),
          },
          {
            path: 'drafts',
            name: 'Draft',
            component: () => import('@/modules/profile/activity/pages/draft.vue'),
          },
          {
            path: 'followings',
            name: 'Following',
            component: () => import('@/modules/profile/activity/pages/following.vue'),
          },
          {
            path: 'invite',
            name: 'Invite',
            component: () => import('@/modules/profile/activity/pages/invite.vue'),
          },
          {
            path: 'notifications',
            name: 'Notifications',
            component: () => import('@/modules/profile/activity/pages/notifications.vue'),
          },
        ],
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('@/modules/profile/pages/setting.vue'),
        children: [
          {
            path: '/',
            redirect: 'account',
          },
          {
            path: 'account',
            name: 'Account',
            component: () => import('@/modules/profile/setting/pages/account.vue'),
          },
          {
            path: 'customize-profile',
            name: 'Customize Profile',
            component: () => import('@/modules/profile/setting/pages/customize-profile.vue'),
          },
        ],
      },
      {
        path: 'nft',
        name: 'NFT',
        component: () => import('@/modules/profile/pages/nft.vue'),
      },
    ],
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/modules/register/pages/register.vue'),
  },
  {
    path: '/search/forum',
    name: 'Search',
    component: () => import('@/modules/search/pages/search.vue'),
  },
  {
    path: '/search/input',
    name: 'Search input',
    component: () => import('@/modules/search/pages/search-input.vue'),
  },
  {
    path: '/connect/:provider/redirect',
    name: 'Connect Discord',
    component: () => import('@/modules/profile/pages/loginRedirect.vue'),
  },
  {
    path: '/invite-link/:id',
    name: 'Invite Dao',
    component: () => import('@/modules/community-organize/pages/invite-by-link.vue'),
  },
  {
    path: '/mint',
    name: 'Mint',
    component: () => import('@/modules/mint/pages/mint.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/modules/test/test-page.vue'),
  },
  {
    path: '/fractal-callback',
    name: 'Fractal Callback',
    component: () => import('@/modules/fractal/pages/fractal-callback-page.vue'),
    meta: {
      appbar: false,
    },
  },
  {
    path: '/popo-detail',
    name: 'Popo Detail',
    component: () => import('@/modules/popo/pages/popo-detail.vue'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/connect-social',
    name: 'Connect social',
    meta: {
      auth: false,
    },
    children: [
      {
        path: '/github-callback',
        name: 'Github Callback',
        component: () => import('@/modules/connect-socials/github-callback-page.vue'),
      },
    ],
  },
  {
    path: '/change-wallet',
    name: 'Change Wallet',
    component: () => import('@/modules/change-wallet/pages/change-wallet.vue'),
    meta: {
      appbar: false,
    },
  },
  {
    path: '/update-wallet',
    name: 'Update Wallet',
    component: () => import('@/modules/change-wallet/pages/update-wallet.vue'),
    meta: {
      appbar: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior() {
  //   document.getElementById('app')?.scrollIntoView({ behavior: 'smooth' })
  // },
})

router.beforeEach(async (to, from, next) => {
  await when(() => walletStore.loaded)

  const requiredAuth = to.matched.some((m) => m.meta?.auth === true)
  // const verifyUserAction = to.matched.some((m) => m.meta?.verifyUserAction === true)
  const loggedIn = walletStore.isLogin
  const jwt = walletStore.jwt
  // const isDirectDirection = !from?.matched?.length

  const loggedInRedirect = reverse([...to.matched]).find((x) => x.meta?.loggedInRedirect)?.meta?.loggedInRedirect
  const jwtRedirect = reverse([...to.matched]).find((x) => x.meta?.jwtRedirect)?.meta?.jwtRedirect
  const isSocialCallback = reverse([...to.matched]).find((x) => x.meta?.socialCallback)?.meta?.socialCallback
  if (to.query && to.query.ref) {
    localData.setRef(to.query.ref as any)
  }
  if (!isSocialCallback) {
    localData.setLinkEmail2Wallet(null)
  }
  console.log('=======from', from, to)
  if (jwt && jwtRedirect) {
    next(jwtRedirect)
  } else if (requiredAuth && !loggedIn) {
    next('/community/trending')
  } else if (loggedIn && !requiredAuth && loggedInRedirect) {
    next(loggedInRedirect)
  }
  // else if (verifyUserAction) {
  //   if (walletStore.jwt && walletStore.isKycVerified) next()
  //   else {
  //     if (isDirectDirection) next('/community/my-page')
  //     walletStore.verifyUserAction()
  //   }
  // }
  else if (walletStore.isLogin && localData.getTargetRouter()) {
    const targetRouter = localData.getTargetRouter()
    localData.setTargetRouter(null)
    next(targetRouter)
  } else next()
})

export default router
