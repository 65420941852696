import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import qs from 'qs'
import { ApiRouteType } from '../types'

export class ProfileHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}
  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, { params })
    return res.data
  }

  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`)
    } else {
      res = await this.axios.get(`${this.route}`)
    }
    const result = res.data
    return result
  }

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, { params })
    const lst = res.data
    return lst
  }

  async createUserProfile(model) {
    const ref = localData.getRef()
    const path = ref ? `${this.route}/create-user-profile?referral=${ref}` : `${this.route}/create-user-profile`
    const res = await this.axios.post(path, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    localData.setRef('')
    return res.data
  }

  async updateUserProfile(model) {
    const res = await this.axios.post(`${this.route}/update-user-profile`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async getMostActiveUsers<T>(params?: { page?: number; pageSize?: number; followedUsers: any }): Promise<T[]> {
    let res
    if (localData.getAccessToken() && walletStore.userProfile) {
      res = await this.axios.get(`/get-most-active-users`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      })
    } else {
      res = await this.axios.get(`/get-most-active-users`, {
        params,
      })
    }
    return res.data
  }

  async getUsersToFollow<T>(params?: any): Promise<T[]> {
    let res
    if (localData.getAccessToken() && walletStore.userProfile) {
      res = await this.axios.get(`/get-users-to-follow`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      })
    } else {
      res = await this.axios.get(`/get-users-to-follow`, {
        params,
      })
    }
    return res.data
  }

  async getUserProfile<T>(params?: any): Promise<T[]> {
    const res = await this.axios.get(`/get-user-profile`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async updateSocialProfile(model) {
    const res = await this.axios.post(`${this.route}/update-social-profile`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }
  async updateAvatar(model) {
    const res = await this.axios.post(`${this.route}/update-avatar`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }
  async setActiveDecoratedPoPo(model: { decoratedPoPoId: string }) {
    const res = await this.axios.post(`${this.route}/set-active-popo`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async searchUsers(params) {
    let res
    if (localData.getAccessToken() && walletStore.userProfile) {
      res = await this.axios.get(`/search-users`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      })
    } else {
      res = await this.axios.get(`/search-users`, {
        params,
      })
    }
    return res.data
  }

  async getRecentReferralUsers(id: string, params) {
    const query = qs.stringify(params)
    const res = await this.axios.get(`/referral/${id}/get-recent-referred-users?${query}`)
    return res.data
  }

  async getRecentReferralSummaryInfo(id: string) {
    const res = await this.axios.get(`/referral/${id}/get-referral-summary`)
    return res.data
  }

  async getReferralRewardActions(id: string) {
    const res = await this.axios.get(`/referral/${id}/get-referral-reward-actions`)
    return res.data
  }

  async getReferralGraphData(id: string, range) {
    const res = await this.axios.get(`/referral/${id}/get-referral-graph-data?range=${range}`, {
      params: {},
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async getRecentReferralDaos(id: string, params) {
    const query = qs.stringify(params)
    const res = await this.axios.get(`/referral/${id}/get-recent-referred-daos?${query}`)
    return res.data
  }

  async getReferralDaoGraphData(id: string, range) {
    const res = await this.axios.get(`/referral/${id}/get-referral-dao-graph-data?range=${range}`, {
      params: {},
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async changeWallet(body) {
    const res = await this.axios.post(`${this.route}/change-wallet`, body, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }
}
