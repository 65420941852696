import { dispatcher, FollowTypeEnum } from '@/stores/dispatcher'
import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import { ApiRouteType } from '../types'

export class UserFollowHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}
  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
      },
    })
    return res.data
  }

  async getUserFollowState<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(`get-users-follow-state`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async getUserFollowRecommend<T>(params?: any): Promise<T[]> {
    const res = await this.axios.get(`get-users-follow-recommend`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async followUser(tag: T): Promise<T> {
    if (!walletStore.userProfile?._id) throw 'User profile is invalid'
    const res = await this.axios.post('follow-user', tag, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    dispatcher.$profileChanged.next({
      from: walletStore.userProfile._id,
      to: (tag as any).follow,
      followState: true,
      type: FollowTypeEnum.user,
    })
    return res.data
  }

  async unFollowUser(tag: T): Promise<T> {
    if (!walletStore.userProfile?._id) throw 'User profile is invalid'
    const res = await this.axios.post('unfollow-user', tag, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    dispatcher.$profileChanged.next({
      from: walletStore.userProfile._id,
      to: (tag as any).follow,
      followState: false,
      type: FollowTypeEnum.user,
    })

    return res.data
  }

  async getUserFollowRecommends<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(`/get-users-follow-recommend`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }
}
