import algosdk from 'algosdk'

const algodServer = process.env.VUE_APP_ALGORAND_SERVER || ''
// const token =
//   process.env.NODE_ENV === 'production'
//     ? (process.env.VUE_APP_ALGORAND_API_KEY || '')
//     : {
//         'X-API-Key': process.env.VUE_APP_ALGORAND_API_KEY || '',
//       }
const token = {
  'X-API-Key': process.env.VUE_APP_ALGORAND_API_KEY || '',
}

export const algoClient = new algosdk.Algodv2(token, algodServer, '')
