import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { RecruitmentApplyModel } from '@/models/recruitment-apply-model'
import { apiService } from '@/services/api-services'
import { computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class ApplicantStore {
  @observable model: RecruitmentApplyModel = undefined as any
  @observable approveLoading = false
  @observable rejectLoading = false
  constructor(model) {
    this.setModel(model)
  }
  setModel(model) {
    this.model = model
  }

  @asyncAction *updateStatus(status: 'applied' | 'rejected') {
    try {
      if (status === 'applied') this.approveLoading = true
      else this.rejectLoading = true
      yield apiService.recruitmentApplyHandler.updateStatus(this.model.id, status)
      this.model.status = status
    } catch (e) {
      snackController.commonError(e)
    } finally {
      if (status === 'applied') this.approveLoading = false
      else this.rejectLoading = false
    }
  }

  @computed get isPending() {
    return this.model.status === 'pending'
  }
  @computed get isRejected() {
    return this.model.status === 'rejected'
  }
  @computed get isApplied() {
    return this.model.status === 'applied'
  }
}
