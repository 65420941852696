import { ApiRouteType } from "../types"

export class InvitationLinkHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`)
    } else {
      res = await this.axios.get(`${this.route}`)
    }
    const result = res.data
    return result
  }
}
