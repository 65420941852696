import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import 'vuetify/dist/vuetify.min.css'
import { VSelect, VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 0,
      sm: 737,
      md: 1280,
      lg: 1728,
    },
    mobileBreakpoint: 1280,
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: '#D6F76B',
          lighten1: '#5C5C5C',
          lighten2: '#8D8D8D',
          lighten3: '#696B7C',
          lighten4: '#ffffff',
          darken1: '#1F2329',
          darken2: '#16181C',
          darken3: '#111111',
          darken4: '#000000',
        },
        'c-primary': '#5947F5',
        'popo-top': '#8bc7ff',
        'popo-bottom': '#ffbe82',
        'popo-accessory1': '#d6f76b',
        'popo-accessory2': '#ac8eff',
      },
      dark: {
        primary: {
          base: '#D6F76B',
          lighten1: '#5C5C5C',
          lighten2: '#8D8D8D',
          lighten3: '#696B7C',
          lighten4: '#ffffff',
          darken1: '#1F2329',
          darken2: '#16181C',
          darken3: '#111111',
          darken4: '#000000',
        },
        'c-primary': '#D6F76B',
        'popo-top': '#8bc7ff',
        'popo-bottom': '#ffbe82',
        'popo-accessory1': '#d6f76b',
        'popo-accessory2': '#ac8eff',
        landing: '#5947F5',
        tgrey: {
          base: '#8D8D8D',
        },
        background: '#000000',
        error: {
          base: '#FF426F',
        },
        'c-black': {
          base: '#1F2329',
        },
        'c-grey': {
          base: '#262626',
        },
      },
    },
  },
})

Vue.component('v-select-custom', {
  extends: VSelect,
  props: {
    solo: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 60,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
})
Vue.component('v-text-field-custom', {
  extends: VTextField,
  props: {
    solo: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 60,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
})

Vue.component('v-text-field-draggable', {
  extends: VTextField,
  props: {
    solo: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },
    height: {
      type: Number,
      default: 60,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
})

Vue.component('v-textarea-custom', {
  extends: VTextarea,
  props: {
    solo: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: true,
    },

    hideDetails: {
      type: Boolean,
      default: true,
    },
    noResize: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 5,
    },
  },

  // computed: {
  //   classes(): any {
  //     return {
  //       'font-size-30': true,
  //     }
  //   },
  // },
})
