








































































































import { promiseHelper } from '@/helper/promise-helper'
import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { accountSettingController } from './account-setting-controller'

@Observer
@Component
export default class extends Vue {
  controller = accountSettingController
  wallet = walletStore

  async connectSocial(provider: string) {
    localData.setLinkEmail2Wallet({ walletAddress: walletStore.account, provider })
    window.open(`${process.env.VUE_APP_API_ENDPOINT}/connect/${provider}`, '_self')
  }
}
