import { appProvider } from '@/app-provider'
import { PostModel } from '@/models/post-model'
import { action, computed, observable } from 'mobx'

export class PostShareStore {
  @observable openPostShareDialog = false
  @observable selectedPost?: PostModel

  @action.bound setOpenPostShareDialog(value: boolean) {
    this.openPostShareDialog = value
  }

  @action.bound setSelectedPost(post: PostModel) {
    this.selectedPost = post
  }

  @action.bound handleSharePost(post: PostModel) {
    this.setSelectedPost(post)
    this.setOpenPostShareDialog(true)
  }

  @computed get postLink() {
    const host = process.env.VUE_APP_HOST
    return `${host}community/post/${this.selectedPost?.id}`
  }

  @computed get facebookLink() {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.postLink}&amp;src=sdkpreparse`
  }

  @computed get twitterLink() {
    return `https://twitter.com/intent/tweet?url=${this.postLink}`
  }

  @computed get redditLink() {
    return `https://www.reddit.com/submit?url=${this.postLink}&title=${this.selectedPost?.title}`
  }

  @computed get kakaoLink() {
    return `https://story.kakao.com/share?url=${this.postLink}`
  }
}

export const postShareStore = new PostShareStore()
