import { PoolModel } from '@/models/pool-model'
import { InvestorModel } from '@/models/investor-model'
import { MintPhaseHandler } from './handlers/minPhaseHandler'
import { ApiHandler } from './handlers/apiHandler'
import { ApiHandlerJWT } from './handlers/apiHandlerJWT'
import { PostHandler } from './handlers/postHandler'
import { VaultHandler } from './handlers/vaultHandler'
import { CommentHandler } from './handlers/commentHandler'
import { TagFollowHandler } from './handlers/tagFollowHandler'
import { UserFollowHandler } from './handlers/userFollowHandler'
import { ReportHandler } from './handlers/reportHandler'
import { BlockHandler } from './handlers/blockHandler'
import { axios } from './axios-config'
import { ProfileHandler } from './handlers/profileHandler'
import { LikeHandler } from './handlers/likeHandler'
import { NotificationHandler } from './handlers/notificationHandler'
import { VoteHandler } from './handlers/voteHandler'
import { MarketplaceItemHandler } from './handlers/marketplaceItemHandler'
import { BookmarkHandler } from './handlers/bookmarkHandler'
import { DaoCategoryHandler } from './handlers/daoCategoryHandler'
import { DaoHandler } from './handlers/daoHandler'
import { LinkHandler } from './handlers/linkHandler'
import { PollHandler } from './handlers/pollHandler'
import { UserDaoHandler } from './handlers/userDaoHandler'
import { DaoRequestHandler } from './handlers/daoRequestHandler'
import { InvitationLinkHandler } from './handlers/invitationLinkHandler'
import { ReviewCategoryHandler } from './handlers/reviewCategoryHandler'
import { MintWhitelistApplyHandler } from './handlers/mintWhitelistApplyHandler'
import { EarningActionHandler } from './handlers/earningActionHandler'
import { FractalUserHandler } from './handlers/fractalUserHandler'
import { PopoHandler } from './handlers/popoHandler'
import { WearableNftHandler } from './handlers/wearableNftHandler'
import { DecoratedPopoHandler } from './handlers/decoratedPopoHandler'
import { RecruitmentHobbyHandler } from './handlers/recruitmentHobbyHandler'
import { RecruitmentTeamCategoryHandler } from './handlers/recruitmentTeamCategoryHandler'
import { RecruitmentAreaHandler } from './handlers/recruitmentAreaHandler'
import { RecruitmentInfoHandler } from './handlers/recruitmentInfoHandler'
import { RecruitmentNoHandler } from './handlers/recruitmentNoHandler'
import { RecruitmentHandler } from './handlers/recruitmentHandler'
import { RecruitmentApplyHandler } from './handlers/recruitmentApplyHandler'

export type VaultApiRoute = 'notes' | 'mint-phases' | 'mint-transactions' | 'pens'

// axios.interceptors.response.use(
//   (response) => {
//     return response
//   },
//   (error) => {
//     get(error, 'response.status') === 401
//     // return Promise.reject(error)
//     snackController.error("Your session was expired. Please login for using Thinkin!")
//     // window.location.reload()
//     walletStore.setShowRequireLoginDialog(true)
//   }
// )
export class ApiService {
  pools = new ApiHandler<PoolModel>(axios, 'pools')
  users = new ApiHandlerJWT<any>(axios, 'users')
  profiles = new ProfileHandler<any>(axios, 'profiles')
  applies = new ApiHandlerJWT<any>(axios, 'applies')
  investors = new ApiHandlerJWT<InvestorModel>(axios, 'investors')
  logs = new ApiHandler<any>(axios, 'logs')
  tickets = new ApiHandlerJWT<any>(axios, 'tickets')
  stakingStatistics = new ApiHandler<any>(axios, 'staking-statistics')
  noteNfts = new VaultHandler<any>(axios, 'notes')
  mintingPhases = new MintPhaseHandler<any>(axios, 'mint-phases')
  mintTransaction = new VaultHandler<any>(axios, 'mint-transactions')
  posts = new PostHandler<any>(axios, 'posts')
  comments = new CommentHandler<any>(axios, 'comments')
  tags = new ApiHandlerJWT<any>(axios, 'tags')
  likes = new LikeHandler<any>(axios, 'likes')
  tagFollows = new TagFollowHandler<any>(axios, 'tag-followings')
  userFollows = new UserFollowHandler<any>(axios, 'user-followings')
  marketplaceItems = new MarketplaceItemHandler<any>(axios, 'marketplace-items')
  penNfts = new VaultHandler<any>(axios, 'pens')
  reports = new ReportHandler<any>(axios, 'reports')
  blocks = new BlockHandler<any>(axios, 'blocks')
  notifications = new NotificationHandler<any>(axios, 'notifications')
  votes = new VoteHandler<any>(axios, 'votes')
  bookmarks = new BookmarkHandler<any>(axios, 'bookmarks')
  links = new LinkHandler<any>(axios, 'links')
  daos = new DaoHandler<any>(axios, 'daos')
  polls = new PollHandler<any>(axios, 'polls')
  userDao = new UserDaoHandler<any>(axios, 'user-daos')
  daoCategoryHandler = new DaoCategoryHandler<any>(axios, 'dao-categories')
  daoRequest = new DaoRequestHandler<any>(axios, 'dao-requests')
  inviteLink = new InvitationLinkHandler<any>(axios, 'invitation-links')
  reviewCategories = new ReviewCategoryHandler<any>(axios, 'review-categories')
  mintWhitelistApplies = new MintWhitelistApplyHandler(axios, 'mint-whitelist-applies')
  earningActions = new EarningActionHandler(axios, 'earning-actions')
  fractalUsers = new FractalUserHandler(axios, 'fractal-users')
  popoNfts = new PopoHandler(axios, 'popo-nfts')
  wearableNfts = new WearableNftHandler(axios, 'wearable-nfts')
  decoratedPopos = new DecoratedPopoHandler(axios, 'decorated-popos')
  recruitmentHobbyHandler = new RecruitmentHobbyHandler(axios, 'recruitment-hobbies')
  recruitmentTeamCategoryHandler = new RecruitmentTeamCategoryHandler(axios, 'recruitment-team-categories')
  recruitmentAreaHandler = new RecruitmentAreaHandler(axios, 'recruitment-areas')
  recruitmentInfoHandler = new RecruitmentInfoHandler(axios, 'recruitment-infos')
  recruitmentNoHandler = new RecruitmentNoHandler(axios, 'recruitment-nos')
  recruitmentHandler = new RecruitmentHandler(axios, 'recruitments')
  recruitmentApplyHandler = new RecruitmentApplyHandler(axios, 'recruitment-applies')
}

export const apiService = new ApiService()
