import { ethers } from "ethers";
import walletStore from '../../stores/wallet-store'
class Application {
  provider = undefined
  signer = undefined
  chainId = 10
  constructor({ test = false, mainnet = true, network = "BSC" }) {
    this.test = test;
    this.mainnet = mainnet;
    if (network != "BSC") {
      throw new Error("Network has to be BSC");
    }
    this.network = network;
    this.isMetamask = false;
    this.login()
  }
  start = () => {
    if (typeof window.ethereum !== "undefined") {
      this.isMetamask = true;
    } else {
      if (!this.test) {
        this.isMetamask = false;
        throw new Error("Please Use an Ethereum Enabled Browser like Metamask or Coinbase Wallet");
      }
    }
  };
  login = async () => {
    if (typeof window === "undefined") {
      return false;
    }
    if (window.ethereum) {
      this.provider = new ethers.providers.Web3Provider(window.ethereum)
      await this.provider.send("eth_requestAccounts", [])
      this.signer = this.provider.getSigner()
      this.chainId = await this.getChainId()
      if(this.chainId !== 10)  {
        await this.switchNetwork()
      }
      return true;
    }
    return false;
  };
  /* Get User Address */
  getAddress = async () => {
    const selectedAddress =  await this.signer.getAddress();
    return selectedAddress
  };

  getChainId= async () => {
    const res = await this.provider.getNetwork()
    return res.chainId
  };

  async switchNetwork() {
    try {
      console.log("======ssss")
      await this.provider.send(
        'wallet_switchEthereumChain',
        [{ chainId: '0xa' }],
      )
    } catch (error) {
      console.log("Message", error)
      if (error.message.includes('wallet_addEthereumChain')) {
          await this.provider.send(
            'wallet_addEthereumChain',
            [
              {
                chainId: '0xa',
                chainName: 'OP Mainnet',
                nativeCurrency: {
                  name: 'Optimisimn',
                  symbol: 'ETH',
                  decimals: 18
                },
                rpcUrls: [
                  'https://mainnet.optimism.io',
                  'https://optimism.llamarpc.com',
                  'https://optimism.blockpi.network/v1/rpc/public',
                  'https://api.zan.top/node/v1/opt/mainnet/public'
                ],
                blockExplorerUrls: ['https://optimistic.etherscan.io/']
              }
            ]
          )
      }
    }
  
}

  /* Get User Balance in Ethereum */
}

export default Application;
