import { localData } from '@/stores/local-data'
import { ApiRouteType } from '../types'

export class MarketplaceItemHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`)
    } else {
      res = await this.axios.get(`${this.route}`)
    }
    const result = res.data
    return result
  }

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:ASC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, { params })
    const lst = res.data
    return lst
  }

  async syncActiveState(body: { itemId: string }) {
    const res = await this.axios.post(`/active-marketplace-item`, body, {})
    return res.data
  }

  async createMarketplaceItem(body: { appId: number; nftType: string }) {
    const res = await this.axios.post(`/create-marketplace-item`, body, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }
}
