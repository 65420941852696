import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { componentRegister } from './plugins/component-register'
import router from './router'
import vuetify from './plugins/vuetify'
import { vueFilterRegister } from './plugins/vue-filter-register'
import { pluginsRegister } from './plugins/plugins-register'
import { directiveRegister } from './plugins/directive-register'
import VueQuillEditor from 'vue-quill-editor'
import VEmojiPicker from 'v-emoji-picker'
import VueApexCharts from 'vue-apexcharts'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import infiniteScroll from 'vue-infinite-scroll'
import MagicUrl from 'quill-magic-url'
import Quill from 'quill'
Quill.register('modules/magicUrl', MagicUrl)

Vue.use(VueApexCharts)
Vue.use(infiniteScroll)
Vue.use(VueQuillEditor, {
  modules: {
    toolbar: {
      container: [['bold', 'italic'], ['blockquote'], ['link'], [{ size: ['small', false] }]],
    },
    magicUrl: true,
  },
  formats: ['link', 'size', 'bold', 'italic'],
  placeholder: 'Write your thoughts',
  theme: 'bubble',
  readOnly: false,
})

Vue.component('apexchart', VueApexCharts)
Vue.use(VEmojiPicker)

Vue.config.productionTip = false
directiveRegister()
componentRegister()
vueFilterRegister()
pluginsRegister()

// Vue.use(VueAnalytics, {
//   id: 'G-0C08J4KQCP',
//   router,
// })

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  directives: { infiniteScroll },
}).$mount('#app')
