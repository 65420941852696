import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { DEFAULT_AUDIENCE_DETAILS, DEFAULT_POLL_VOTES, DEFAULT_REVIEW_CATEGORIES } from '@/constants'
import { numberHelper } from '@/helper/number.hepler'
import { b64toBlob, objIsNotEmpty } from '@/helper/utils'
import { CommunityModel } from '@/models/community-model'
import { PostModel, TagModel } from '@/models/post-model'
import { apiService } from '@/services/api-services'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { flattenDeep, map, some, sumBy, uniq } from 'lodash'
import { action, computed, observable, toJS } from 'mobx'
import { asyncAction } from 'mobx-utils'
import moment from 'moment'
import { EditorBlock, EditorHandler } from './editor-handler'

export class CreatePostController {
  @observable completedUpdateType: undefined | 'create' | 'modify' | 'draft' = undefined
  @observable createPostDialog = false
  @observable isEditPost = false
  @observable isEnableEditPoll = true
  @observable postId: any = ''
  @observable postTitle = ''

  @observable createPostLoading = false
  @observable selectedPost?: PostModel = undefined
  @observable isReadyVideoDuration = false
  //poll section
  @observable isPollSelected = false
  @observable pollCount = 2
  @observable pollVotes = DEFAULT_POLL_VOTES
  @observable pollDurationByDay: any = ''
  @observable pollDurationByHour: any = ''
  @observable pollDurationByMinute: any = ''
  @observable pollDurationByDayError = ''
  @observable pollDurationByHourError = ''
  @observable pollDurationByMinuteError = ''
  @observable isCreatePost = true
  @observable type?: 'modify' | 'draft' = undefined
  @observable communityId?: string = undefined
  @observable community?: CommunityModel = undefined
  @observable relatedCommunities: CommunityModel[] = []
  @observable getAllRelatedDaosLoading = false

  // audience
  @observable selectedDao?: CommunityModel = undefined
  @observable audience?: 'everyone' | 'review' | 'my-dao' = undefined
  @observable audienceError = ''
  @observable isValidPostType = false
  @observable audienceDetails = DEFAULT_AUDIENCE_DETAILS as any
  @observable audienceDetail = undefined as any
  @observable audienceDetailError = ''

  reviewCategories = DEFAULT_REVIEW_CATEGORIES
  @observable selectedReviewCategories = []
  @observable reviewCategoryError = ''
  @observable reviewRating?: number = undefined
  @observable reviewRatingError = ''

  @observable repostedPostStore?: PostStore
  @observable isExpandReviewTip = false
  @observable isValidInput = true
  @observable isValidLetterCount = true
  @observable openConfirmDiscardPostDialog = false
  @observable openReviewGuidelineDialog = false

  @observable captchaToken = ''

  @observable editorHandler: EditorHandler = undefined as any

  @computed get editorBlocks(): EditorBlock[] {
    return this.editorHandler.editorBlocks
  }

  constructor() {
    this.editorHandler = new EditorHandler()
  }

  @action resetHCaptchaToken() {
    this.captchaToken = ''
  }

  @action resetRelatedAudienceFields() {
    this.audience = undefined
    this.selectedDao = undefined
    this.audienceDetails = DEFAULT_AUDIENCE_DETAILS
    this.audienceDetail = undefined
    this.reviewRating = undefined
    this.selectedReviewCategories = []
    this.isExpandReviewTip = false
  }

  @action clearPostData() {
    this.postTitle = ''
    this.isEditPost = false
    this.type = undefined
    this.community = undefined
    this.communityId = undefined
    this.selectedPost = undefined
    this.repostedPostStore = undefined
    this.clearPoll()

    this.editorHandler.clearEditorData()
  }

  @action clearPoll() {
    this.isPollSelected = false
    this.pollVotes = DEFAULT_POLL_VOTES.map((item) => ({ ...item }))
    this.pollDurationByDay = ''
    this.pollDurationByHour = ''
    this.pollDurationByMinute = ''
    this.pollDurationByDayError = ''
    this.pollDurationByHourError = ''
    this.pollDurationByMinuteError = ''
    this.isEnableEditPoll = true
  }

  @action changeOpenConfirmDiscardPostDialog(value) {
    this.openConfirmDiscardPostDialog = value
  }

  @action changeOpenReviewGuidelineDialog(value) {
    this.openReviewGuidelineDialog = value
  }

  @action onDiscardPost() {
    this.openConfirmDiscardPostDialog = false
    this.show(false)
  }

  @action.bound show(
    value: boolean,
    post: PostModel | undefined = undefined,
    type: undefined | 'modify' | 'draft' = undefined,
    community: CommunityModel | undefined = undefined,
    repostedPostStore: PostStore | undefined = undefined
  ) {
    this.createPostDialog = value
    this.clearPostData()
    this.editorHandler.fetchTags()
    this.resetRelatedAudienceFields()
    this.getAllRelatedDaos()
    this.resetPostTypeError()
    if (post) {
      this.selectedPost = post
      this.type = type
      this.postId = post.id
      this.isEnableEditPoll = false
      this.isEditPost = true
      this.editorHandler.setTagLocation(post.location, post.mapLocation)
      if (post.rePost && !repostedPostStore) {
        this.repostedPostStore = new PostStore(post.rePost)
      }

      this.setPostData(post)
      this.createPostDialog = true
      this.audience = post.type === 'recruitment' ? 'everyone' : post.type

      if (this.isReviewAudience) {
        this.changeRewviewCategory(post.reviewCategories)
        this.changeReviewRating(post.reviewRating)
      }
    }
    if (repostedPostStore) {
      this.repostedPostStore = repostedPostStore
      if (repostedPostStore.post?.dao && repostedPostStore.post?.type !== 'recruitment') {
        this.setCommunityInfo(repostedPostStore.post?.dao)
      }
    } else {
      this.setCommunityInfo(community || post?.dao, post)
    }
  }

  @action setCommunityInfo(community?: CommunityModel, post: PostModel | undefined = undefined) {
    if (!community) return
    this.community = community
    this.communityId = community?._id
    this.setCommunityMenu(community)
    this.selectedDao = community
    this.audience = 'my-dao'
    if (post && post?.daoMenu) {
      this.audienceDetail = this.audienceDetails.find((item) => item.id === post.daoMenu)?.id
    }
  }

  @action selectAudienceChange(obj: { type?: 'everyone' | 'review' | 'my-dao'; selectedDao?: CommunityModel }) {
    this.audience = obj.type
    this.selectedDao = obj.selectedDao
    if (this.selectedDao) this.setCommunityMenu(this.selectedDao)
    this.audienceDetail = undefined
    this.reviewRating = undefined
    this.audienceError = ''
    this.audienceDetailError = ''
    this.selectedReviewCategories = []
    this.isExpandReviewTip = false
  }

  @action.bound toggleExpandReviewTip() {
    this.isExpandReviewTip = !this.isExpandReviewTip
  }

  @action changeAudienceDetail(input) {
    this.audienceDetail = input
    this.audienceDetailError = ''
  }

  @action changeRewviewCategory(input) {
    this.selectedReviewCategories = input
    this.reviewCategoryError = ''
  }

  @action changeReviewRating(value) {
    this.reviewRating = value
    this.reviewRatingError = ''
  }

  @action resetPostTypeError() {
    this.isValidInput = true
    this.isValidLetterCount = true
    this.audienceError = ''
    this.reviewRatingError = ''
    this.reviewCategoryError = ''
    this.audienceDetailError = ''
  }

  @action verifyPostType() {
    if (!this.audience) {
      this.audienceError = 'invalid'
      this.isValidInput = false
    }
    switch (this.audience) {
      case 'review':
        if (!objIsNotEmpty(this.selectedReviewCategories)) {
          this.reviewCategoryError = 'Invalid'
          this.isValidInput = false
        }
        if (!objIsNotEmpty(this.reviewRating)) {
          this.reviewRatingError = 'Invalid'
          this.isValidInput = false
        }
        break
      case 'my-dao':
        if (!objIsNotEmpty(this.audienceDetail)) {
          this.audienceDetailError = 'Invalid'
          this.isValidInput = false
        }
        break
    }
  }

  @computed get isSelectedMyDao() {
    return this.audience === 'my-dao'
  }

  @computed get isReviewAudience() {
    return this.audience === 'review'
  }

  @asyncAction *setCommunityMenu(community: CommunityModel | undefined) {
    try {
      let menu = [...DEFAULT_AUDIENCE_DETAILS] as any
      const ownerCommunityId = community?.profile?._id ? community?.profile?._id : community?.profile
      if (walletStore.userProfile?._id && walletStore.userProfile?._id == ownerCommunityId) {
        menu.push({
          id: 'announcement',
          title: 'Announcement',
        })
      } else if (community?._id && walletStore.userProfile?._id) {
        const contributors = yield apiService.daos.getContributors(community?._id)
        const contributorIds = map(contributors, '_id')
        if (contributorIds.includes(walletStore.userProfile._id)) {
          menu.push({
            id: 'announcement',
            title: 'Announcement',
          })
        }
      }
      const communityMenu = community?.menu?.list
      if (communityMenu?.length) {
        const formatedCommunityMenu = JSON.parse(JSON.stringify(communityMenu))
        menu = [...menu, ...formatedCommunityMenu]
      }
      this.audienceDetails = menu
    } catch (e) {
      console.log('error setCommunityMenu,e')
    }
  }
  @action changePostMode(value) {
    this.isCreatePost = value
  }

  @action changePostTitle(input) {
    this.postTitle = input
  }

  @asyncAction *getAllRelatedDaos() {
    try {
      this.getAllRelatedDaosLoading = true
      this.relatedCommunities = yield apiService.daos.getAllRelatedDaos()
    } catch (error) {
      console.log('getAllRelatedDaos Error: ', error)
    } finally {
      this.getAllRelatedDaosLoading = false
    }
  }

  @action setPostData(post: PostModel) {
    if (post.title) this.postTitle = post.title

    this.editorHandler.setEditorData(post)

    if (!post.poll) this.isEnableEditPoll = true
    else {
      const pollVotes = [] as any
      post.poll?.data?.options?.map((item, index) => {
        pollVotes.push({ id: item.index, label: `Option ${index + 1}`, textInput: item.title, error: '' })
      })
      this.pollVotes = pollVotes
      this.isPollSelected = true
      this.pollDurationByDay = post.poll?.data?.durationTime?.days
      this.pollDurationByHour = post.poll?.data?.durationTime?.hours
      this.pollDurationByMinute = post.poll?.data?.durationTime?.minutes

      this.isEnableEditPoll = this.type === 'draft' ? true : false
    }
  }

  @action addPollOption() {
    this.pollCount++
    this.pollVotes.push({ id: this.pollCount, label: `Option ${this.pollVotes.length + 1}`, textInput: '', error: '' })
  }

  removePollOption(id) {
    if (this.pollVotes.length <= 2) return
    this.pollVotes = this.pollVotes.filter((item) => item.id !== id)
  }

  @action changePollStatus(value) {
    this.isPollSelected = value
  }

  @action validPoll() {
    this.pollVotes.forEach((item) => {
      if (!item.textInput || item.textInput.trim().length === 0) {
        item.error = 'Option is empty'
      }
    })
  }

  @action changePollDurationByDay(value) {
    this.pollDurationByDay = value
    this.pollDurationByDayError = ''
  }
  @action changePollDurationByHour(value) {
    this.pollDurationByHour = value
    this.pollDurationByHourError = ''
  }
  @action changePollDurationByMinute(value) {
    this.pollDurationByMinute = value
    this.pollDurationByMinuteError = ''
  }

  @action validPollDuration() {
    if (!this.pollDurationByDay || !numberHelper.isPositiveInterger(this.pollDurationByDay))
      this.pollDurationByDayError = 'Invalid field'
    if (!this.pollDurationByHour || !numberHelper.isPositiveInterger(this.pollDurationByHour))
      this.pollDurationByHourError = 'Invalid field'
    if (!this.pollDurationByMinute || !numberHelper.isPositiveInterger(this.pollDurationByMinute))
      this.pollDurationByMinuteError = 'Invalid field'
  }

  @action getPollDuration() {
    return moment.duration({
      days: +this.pollDurationByDay,
      hours: +this.pollDurationByHour,
      minutes: +this.pollDurationByMinute,
    })
  }

  @action changeVoteInput(id, value) {
    const index = this.pollVotes.findIndex((item) => item.id === id)
    if (index === -1) return
    const pollVotes = [...this.pollVotes]
    pollVotes[index].textInput = value
    pollVotes[index].error = ''
    this.pollVotes = pollVotes
  }

  @asyncAction *uploadMediaFiles() {
    const imageEditors = this.editorBlocks.filter((item) => item.type === 'image-block' && item?.pureFiles?.length)
    const videoEditors = this.editorBlocks.filter((item) => item.type === 'video-block' && item?.pureFile)
    if (videoEditors.length > 1) snackController.error('Invalid video')
    const videoFile = videoEditors[0]?.pureFile?.file
    const videoId = videoEditors[0]?.pureFile?.id
    const imagePureFiles = map(imageEditors, 'pureFiles')

    const flattenImagePureFiles = flattenDeep(imagePureFiles).filter((item) => !item.isEditMediaFile)
    const imageFiles = map(flattenImagePureFiles, 'file')
    const imageIds = map(flattenImagePureFiles, 'id')
    const mediaFiles = imageFiles
    const mediaIds = imageIds
    if (videoFile && !videoEditors[0]?.pureFile.isEditMediaFile) {
      mediaFiles.push(videoFile)
      mediaIds.push(videoId)
    }
    const promises: Promise<any>[] = []
    for (let i = 0; i < mediaFiles.length; i++) {
      const uploadPromise = apiService.posts.upload(mediaFiles[i])
      promises.push(uploadPromise)
    }
    const res = yield Promise.all(promises)
    const mediaIdsRes = map(flattenDeep(res), 'id')
    const mediasRes = []
    for (let i = 0; i < mediaIdsRes.length; i++) {
      mediasRes[`${mediaIds[i]}`] = mediaIdsRes[i]
    }
    if (this.isEditPost) {
      if (videoId && videoEditors[0]?.pureFile.isEditMediaFile) {
        mediasRes[`${videoId}`] = videoId
      }
      const uploadedImagePureFiles = flattenDeep(imagePureFiles).filter((item) => item.isEditMediaFile)
      uploadedImagePureFiles?.map((pureFile) => {
        mediasRes[`${pureFile.id}`] = pureFile.id
      })
    }
    return mediasRes
  }

  @action changeTextBlock(id, text) {
    this.isValidLetterCount = true
    this.editorHandler.changeTextBlock(id, text)
  }

  @asyncAction *buildDataBlockPayload() {
    const uploadedMediaIds = yield this.uploadMediaFiles()
    const uploadedLocationImageId = yield this.uploadLocationImage()
    const blockPayload = [] as any
    let tagInContents = [] as any
    this.editorBlocks.forEach((block, index) => {
      if (block.type === 'image-block') {
        const pureFiles = block?.pureFiles
        if (pureFiles?.length) {
          const uploadedMedias = [] as any
          pureFiles.forEach((rowFiles) => {
            const filesByRow = [] as any
            rowFiles.forEach((file) => {
              filesByRow.push(uploadedMediaIds[file.id])
            })
            uploadedMedias.push(filesByRow)
          })

          blockPayload.push({
            type: 'image',
            files: uploadedMedias,
          })
        }
      } else if (block.type === 'video-block') {
        const pureFileId = block?.pureFile?.id
        if (pureFileId) {
          blockPayload.push({
            type: 'video',
            files: [uploadedMediaIds[pureFileId]],
          })
        }
      } else if (block.type === 'text-block') {
        if (!(index === this.editorBlocks.length - 1 && block.rawContent === '\n')) {
          const contentParser = this.editorHandler.regexHashTag(block.htmlContent)
          tagInContents = [...tagInContents, ...contentParser.tagInContents]
          blockPayload.push({
            type: 'text',
            htmlContent: contentParser.htmlContentWithLink,
            rawContent: block.rawContent,
          })
        }
      } else if (block.type === 'link-block' && block.insertOgInfo?.requestUrl) {
        blockPayload.push({
          type: 'link',
          link: block.insertOgInfo.requestUrl,
        })
      }
    })
    return {
      blockPayload,
      medias: Object.values(uploadedMediaIds),
      uploadedLocationImageId,
      tagInContents: uniq(tagInContents),
    }
  }

  @asyncAction *uploadLocationImage() {
    const tagLocation = this.editorHandler.tagLocation
    const mapLocationId = this.selectedPost?.mapLocation?.id
    if (tagLocation && mapLocationId && !tagLocation?.base64Source) return mapLocationId
    if (!tagLocation?.base64Source) return undefined
    const blob = b64toBlob(tagLocation.base64Source, 'image/png')
    const res = yield apiService.posts.upload(blob)
    return res[0]?._id
  }

  @action buildSomeFieldRelateAudience() {
    let postParams = { type: this.audience } as any
    if (this.audience === 'review') {
      postParams = { ...postParams, reviewCategories: this.selectedReviewCategories, reviewRating: this.reviewRating }
    } else if (this.audience === 'my-dao') {
      postParams = { ...postParams, daoMenu: this.audienceDetail, daoId: this.selectedDao?._id }
    }
    return postParams
  }

  @asyncAction *getPayload(dataBlockPayload = {} as any) {
    let postParams = {
      title: this.postTitle,
      medias: [],
    } as any

    if (dataBlockPayload?.medias?.length) {
      postParams = { ...postParams, medias: dataBlockPayload.medias }
    }
    postParams = { ...postParams, data: { content: dataBlockPayload.blockPayload } }
    if (this.communityId) postParams = { ...postParams, daoId: this.communityId }
    // tags
    const tagInContents = dataBlockPayload.tagInContents
    if (tagInContents?.length) {
      const tagsExisted: TagModel[] = []
      const newTags: string[] = []
      tagInContents.map((item) => {
        const tag = this.editorHandler.tagList.find((tag) => {
          return tag.content === item
        })
        if (tag) tagsExisted.push(tag)
        else newTags.push(item)
      })
      const newTagsCreated = yield this.editorHandler.createTags(newTags)
      const existedTags = toJS(tagsExisted)
      const tags = [...existedTags, ...newTagsCreated]
      postParams = { ...postParams, tags }
    } else {
      postParams = { ...postParams, tags: [] }
    }
    postParams = { ...postParams, status: this.isCreatePost ? 'public' : 'draft' }
    if (this.selectedPost?.poll && this.isEnableEditPoll && !this.isPollSelected) {
      postParams = { ...postParams, poll: null }
      return postParams
    }
    // location
    const mapLocation = dataBlockPayload.uploadedLocationImageId
    postParams = {
      ...postParams,
      mapLocation: mapLocation || null,
      location: this.editorHandler.tagLocation?.location || null,
    }
    postParams = { ...postParams, ...this.buildSomeFieldRelateAudience() }

    // repost
    if (this.repostedPost) {
      postParams = { ...postParams, rePost: this.repostedPost?.id }
    }

    // poll
    if (!this.isPollSelected || !this.isEnableEditPoll) return postParams
    const pollOptions = [] as any
    this.pollVotes.map((item, index) => {
      pollOptions.push({ index, title: item.textInput })
    })
    const pollEndDate = moment().add(this.getPollDuration())
    const poll = {
      endDate: pollEndDate,
      data: {
        options: pollOptions,
        durationTime: {
          days: +this.pollDurationByDay,
          hours: +this.pollDurationByHour,
          minutes: +this.pollDurationByMinute,
        },
        startTime: moment().toISOString(),
      },
    }
    postParams = { ...postParams, poll }
    return postParams
  }

  @action validateInput() {
    this.validPoll()
    if (!this.isValidVotes) this.isValidInput = false
    this.validPollDuration()
    if (!this.isValidPollDuration) this.isValidInput = false
    if (this.getPollDuration().asSeconds() == 0) {
      this.isValidInput = false
    }
  }

  @action validateTextBlocks() {
    if (!this.audience) return
    if (this.getSumLetterTextBlocks < this.validLetterCount) {
      this.isValidLetterCount = false
      this.isValidInput = false
    }
  }

  @action changeCompletedUpdateType(value) {
    this.completedUpdateType = value
  }

  @action verifyPostInputs() {
    this.isValidInput = true
    if (this.isEnableEditPoll && this.isPollSelected) {
      this.validateInput()
    }
    this.verifyPostType()
    this.validateTextBlocks()
  }

  @asyncAction *createPostWithCaptcha(token) {
    try {
      this.verifyPostInputs()
      if (!this.isValidInput) return
      this.createPostLoading = true
      const dataBlockPayload = yield this.buildDataBlockPayload()
      if (!dataBlockPayload.blockPayload.length) {
        snackController.error('Content cannot be empty')
        return
      }
      const payload = yield this.getPayload(dataBlockPayload)
      if (this.isEditPost) {
        yield apiService.posts.updatePost({ id: this.postId, ...payload })
        this.completedUpdateType = this.type
        if (this.type === 'draft')
          snackController.success(this.isCreatePost ? 'Your post have published' : 'Update the draft post completed')
        else snackController.success('Update the post completed')
      } else {
        const CAPTCHA_SITE_KEY = process.env.VUE_APP_HCAPTCHA_SITE_KEY
        const data = { ...payload, captchaSiteKey: CAPTCHA_SITE_KEY, captchaToken: token }
        yield apiService.posts.createPostWithCaptcha(data)
        this.completedUpdateType = 'create'
        snackController.success('Post upload completed')
      }
      this.show(false)
      return true
    } catch (error) {
      snackController.commonError(error)
      return false
    } finally {
      this.createPostLoading = false
    }
  }

  @asyncAction *createPost() {
    try {
      this.verifyPostInputs()
      if (!this.isValidInput) return
      this.createPostLoading = true
      const dataBlockPayload = yield this.buildDataBlockPayload()
      if (!dataBlockPayload.blockPayload.length) {
        snackController.error('Content cannot be empty')
        return
      }
      const payload = yield this.getPayload(dataBlockPayload)
      if (this.isEditPost) {
        yield apiService.posts.updatePost({ id: this.postId, ...payload })
        this.completedUpdateType = this.type
        if (this.type === 'draft')
          snackController.success(this.isCreatePost ? 'Your post have published' : 'Update the draft post completed')
        else snackController.success('Update the post completed')
      } else {
        yield apiService.posts.createPost(payload)
        this.completedUpdateType = 'create'
        snackController.success('Post upload completed')
      }
      this.show(false)
      return true
    } catch (error) {
      snackController.commonError(error)
      return false
    } finally {
      this.createPostLoading = false
    }
  }

  @computed get isValidVotes() {
    return !some(this.pollVotes, (vote) => {
      return !!vote.error
    })
  }
  @computed get isValidPollDuration() {
    return !this.pollDurationByDayError && !this.pollDurationByHourError && !this.pollDurationByMinuteError
  }
  @computed get isCommunityOwner() {
    return this.community && this.community?.profile?._id === walletStore.userProfile?._id
  }

  // handle display repost info
  @computed get repostedPost() {
    return this.repostedPostStore?.post
  }
  @computed get firstMediaRepost() {
    return this.repostedPostStore?.firstMedia
  }

  @computed get firstTextBlockRepost() {
    return this.repostedPostStore?.firstTextBlock
  }

  @computed get repostTitle() {
    return this.repostedPostStore?.title
  }
  @computed get repostProfile() {
    return this.repostedPost?.profile
  }

  @computed get repostCreatedAt() {
    return this.repostedPost?.createdAt
  }

  @computed get firstMediaRepostIsImage() {
    return this.firstMediaRepost?.mime?.includes('image')
  }

  @computed get firstMediaRepostIsVideo() {
    return this.firstMediaRepost?.mime?.includes('video')
  }
  @computed get getSumLetterTextBlocks() {
    return sumBy(this.editorBlocks, (item) => item.letterCount || 0)
  }
  @computed get validLetterCount() {
    return this.audience === 'review' ? 50 : 10
  }
  @computed get showRemindAtleastLetterCount() {
    return this.audience && this.getSumLetterTextBlocks < this.validLetterCount
  }
}

export const createPostController = new CreatePostController()
