import { VaultApiRoute } from '../api-services'

export class VaultHandler<T> {
  constructor(private axios: any, private route: VaultApiRoute) {}
  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
      },
    })
    return res.data
  }

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        // Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    const lst = res.data
    return lst
  }

  async create(model: T): Promise<T> {
    const res = await this.axios.post(this.route, model, {
      headers: {
        ...this.axios.defaults.headers,
      },
    })
    return res.data
  }

  async createNFTMetadata(data: {
    assetID: number
    mintType: string
    walletAddress: string
    distribute: string
    index: number
    phaseId?: string
  }) {
    const res = await this.axios.post(`notes/create-nft-metadata`, data)
    return res.data
  }
}
