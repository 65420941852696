import { dispatcher, FollowTypeEnum } from "@/stores/dispatcher";
import { localData } from "@/stores/local-data";
import { walletStore } from "@/stores/wallet-store";
import { ApiRouteType } from "../types";

export class TagFollowHandler<T> {
    constructor(private axios: any, private route: ApiRouteType) {}
    async count(params?: any): Promise<number> {
      const res = await this.axios.get(`${this.route}/count`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
        },
      })
      return res.data
    }
  
    async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
      const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
      params = { ...settingDefault, ...settings, ...(params ?? {}) }
      const res = await this.axios.get(this.route, { params })
      const lst = res.data
      return lst
    }
  
    async getTagFollowState<T>(
      params?: any,
      settings: { _sort?: string; _limit?: number; _start?: number } = {}
    ): Promise<T[]> {
      const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
      params = { ...settingDefault, ...settings, ...(params ?? {}) }
      const res = await this.axios.get(`get-tags-follow-state`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      })
      return res.data
    }
  
    async followTag(tag: T): Promise<T> {
      if (!walletStore.userProfile?._id) throw 'Profile is undefined'
      const res = await this.axios.post('follow-tag', tag, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
  
      dispatcher.$profileChanged.next({
        from: walletStore.userProfile._id,
        to: (tag as any).tag,
        followState: true,
        type: FollowTypeEnum.tag,
      })
  
      return res.data
    }
  
    async unFollowTag(tag: T): Promise<T> {
      if (!walletStore.userProfile?._id) throw 'Profile is undefined'
      const res = await this.axios.post('unfollow-tag', tag, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
      dispatcher.$profileChanged.next({
        from: walletStore.userProfile._id,
        to: (tag as any).tag,
        followState: false,
        type: FollowTypeEnum.tag,
      })
  
      return res.data
    }
  
    async getTopTagsProfile<T>(profileId: string) {
      let res
      if (localData.getAccessToken()) {
        res = await this.axios.get(`/get-top-tags-profile/${profileId}`, {
          headers: {
            ...this.axios.defaults.headers,
            Authorization: `Bearer ${localData.getAccessToken()}`,
          },
        })
      } else {
        res = await this.axios.get(`/get-top-tags-profile/${profileId}`)
      }
      const result = res.data
      return result
    }
  }