import { localData } from "@/stores/local-data";
import { ApiRouteType } from "../types";

export class LikeHandler<T> {
    constructor(private axios: any, private route: ApiRouteType) {}
    async count(params?: any): Promise<number> {
      const res = await this.axios.get(`${this.route}/count`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
        },
      })
      return res.data
    }
    async getLikedPost<T>(
      params?: any,
      settings: { _sort?: string; _limit?: number; _start?: number } = {}
    ): Promise<T[]> {
      const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
      params = { ...settingDefault, ...settings, ...(params ?? {}) }
      const res = await this.axios.get(`get-liked-posts`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      })
      return res.data
    }
  
    async getMostUserLikedByProfile<T>(profileId: string) {
      let res
      if (localData.getAccessToken()) {
        res = await this.axios.get(`/most-users-liked/${profileId}`, {
          headers: {
            ...this.axios.defaults.headers,
            Authorization: `Bearer ${localData.getAccessToken()}`,
          },
        })
      } else {
        res = await this.axios.get(`/most-users-liked/${profileId}`)
      }
      const result = res.data
      return result
    }
  }
  