import { MAX_SIZE_IMAGE_UPLOAD_BY_BYTE, MAX_SIZE_IMAGE_UPLOAD_BY_MB } from '@/constants'
import { isEmpty, some } from 'lodash-es'
import { formatStringNoExtraSpace } from './utils'

const getNormalizedMedias = (medias) => {
  const normalizedMedias = [] as any
  medias?.map((item) => {
    normalizedMedias[item._id] = {
      url: item.url,
      sizeByByte: item.size ? Math.round(item.size * 1024) : 0,
      sizeByMb: item.size ? Math.round(item.size / 1024) : 0,
    }
  })
  return normalizedMedias
}

const verifyVideoFileInput = (id, file, editorBlocks) => {
  let message = { status: true, error: '' } as any
  const checkEditorBlocks = editorBlocks.filter((item) => item.id !== id && item.type === 'video-block')
  if (some(checkEditorBlocks, (videoBlock) => videoBlock.pureFile?.id))
    message = {
      status: false,
      error: 'Up to one video can only be uploaded',
    }
  else if (file.size > MAX_SIZE_IMAGE_UPLOAD_BY_BYTE)
    message = {
      status: false,
      error: `Video must not exceed ${MAX_SIZE_IMAGE_UPLOAD_BY_MB}MB`,
    }

  return message
}

export const postDialogHelper = { getNormalizedMedias, verifyVideoFileInput }
