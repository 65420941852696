import { ApiRouteType } from '../types'

export class MintPhaseHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}
  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, { params })
    const lst = res.data
    return lst
  }

  async createWhitelistApply(mintPhase: string, data: { walletAddress: string }) {
    const res = await this.axios.post(`/mint-phases/${mintPhase}/mint-whitelist-apply`, data, {})
    return res.data
  }
}
