



















































import { Component, Vue, Provide, Watch } from 'vue-property-decorator'
import { AppProvider, appProvider } from './app-provider'
import AccountSettingDialog from './components/auth/account-setting-dialog.vue'
import WalletSettingDialog from './components/auth/wallet-setting-dialog.vue'
import { createPostController } from './modules/common/dialogs/create-post/create-post-controller'
import { walletStore } from './stores/wallet-store'

@Component({
  components: {
    'navigation-bar': () => import('./components/navbar/navigation-bar.vue'),
    drawer: () => import('./components/navbar/drawer.vue'),
    'global-loading': () => import('@/components/global-loading/global-loading.vue'),
    'snack-bar': () => import('@/components/snack-bar/snack-bar.vue'),
    alert: () => import('@/components/alert/alert.vue'),
    'wallet-connect-required-dialog': () => import('@/components/auth/wallet-connect-required-dialog.vue'),
    'create-post-dialog': () => import('@/modules/common/dialogs/create-post/create-post-dialog.vue'),
    'delete-post-dialog': () => import('@/modules/common/dialogs/post/delete-post-dialog.vue'),
    'blind-reason-user-side-dialog': () => import('@/modules/common/dialogs/post/blind-reason-user-side-dialog.vue'),
    'block-post-owner-dialog': () => import('@/modules/common/dialogs/post/block-post-owner-dialog.vue'),
    'report-post-dialog': () => import('@/modules/common/dialogs/post/report-post-dialog.vue'),
    'public-draft-dialog': () => import('@/modules/common/dialogs/post/public-draft-dialog.vue'),
    'share-dialog': () => import('@/modules/common/dialogs/share-dialog.vue'),
    'post-medias-detail': () => import('@/modules/common/components/posts/post-medias-detail.vue'),
    'reason-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/reason-dialog.vue'),
    'completed-create-dao-dialog': () =>
      import('@/modules/community-organize/components/dao-detail-setting/dialogs/completed-create-dao-dialog.vue'),
    'discovering-great-dialog': () => import('@/components/discovering-great-dialog.vue'),
    'connect-wallet-dialog': () => import('@/components/navbar/connect-wallet-dialog.vue'),
    'kyc-dialog': () => import('@/components/kyc-dialog.vue'),
    'earn-connect-wallet-dialog': () => import('@/components/kyc-dialogs/earn-connect-wallet-dialog.vue'),
    'require-action-connect-wallet-dialog': () =>
      import('@/components/kyc-dialogs/require-action-connect-wallet-dialog.vue'),
    'require-action-verify-kyc-dialog': () => import('@/components/kyc-dialogs/require-action-verify-kyc-dialog.vue'),
    AccountSettingDialog,
    WalletSettingDialog,
    'kyc-warning-dialog': () => import('@/components/dialogs/kyc-warning-dialog.vue'),
    'popo-detail-dialog': () => import('@/modules/popo/dialogs/popo-detail-dialog.vue'),
    'popo-detach-all-dialog': () => import('@/modules/popo/dialogs/detachs/popo-detach-all-dialog.vue'),
    'popo-detach-success-dialog': () => import('@/modules/popo/dialogs/detachs/popo-detach-success-dialog.vue'),
    'popo-detach-single-dialog': () => import('@/modules/popo/dialogs/detachs/popo-detach-single-dialog.vue'),
    'popo-confirm-minting-dialog': () => import('@/modules/popo/dialogs/claims/popo-confirm-minting-dialog.vue'),
    'popo-minting-loading-dialog': () => import('@/modules/popo/dialogs/claims/popo-minting-loading-dialog.vue'),
    'popo-mint-success-dialog': () => import('@/modules/popo/dialogs/claims/popo-mint-success-dialog.vue'),
    'popo-attachment-dialog': () => import('@/modules/popo/dialogs/attachs/popo-attachment-dialog.vue'),
    'popo-decorate-dialog': () => import('@/modules/popo/dialogs/popo-decorate-dialog.vue'),
    'popo-attach-success-dialog': () => import('@/modules/popo/dialogs/attachs/popo-attach-success-dialog.vue'),
    'update-avatar-success-dialog': () => import('@/modules/popo/components/update-avatar-success-dialog.vue'),
    'remind-claim-popo-dialog': () => import('@/modules/popo/dialogs/remind-claim-popo-dialog.vue'),
    'popo-claim-dialog': () => import('@/modules/popo/dialogs/claims/popo-claim-dialog.vue'),
    'recruitment-post-dialog': () => import('@/modules/recruitment/dialogs/recruitment-post-dialog.vue'),
  },
})
export default class App extends Vue {
  @Provide() providers: AppProvider = appProvider
  createPostController = createPostController

  get lastPath() {
    const paths = this.$route.fullPath.split('/')
    return paths[paths.length - 1]
  }

  get hideNavbar() {
    if (
      (this.lastPath === 'summary' || this.lastPath === 'posts' || this.lastPath === 'account') &&
      this.$vuetify.breakpoint.width < 1280
    ) {
      return true
    }

    if (this.lastPath.includes('forum') && this.$vuetify.breakpoint.width < 737) {
      return true
    }

    if (this.$route.name === 'Community Detail' && this.$vuetify.breakpoint.width < 1280) {
      return true
    }

    return false
  }

  get isMobile() {
    return this.$vuetify.breakpoint.width < 1280
  }

  drawerDialog = false

  @Watch('drawerDialog', { immediate: true }) changeDrawerDialog(value) {
    this.providers.changeDrawerDialog(value)
  }
  mounted() {
    this.providers.router = this.$router
    walletStore.start()
  }

  goBack() {
    history.back()
  }

  get showNav() {
    const appbar = this.$route.meta && this.$route.meta.appbar
    if (appbar === false) {
      return false
    } else {
      return true
    }
  }
}
