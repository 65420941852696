import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { isEmpty, map, reduce } from 'lodash'
import { action, computed, observable, reaction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { PostStore } from './post-store'

export class PostDetail {
  @observable postStore?: PostStore
  @observable openPostMediasDetail = false
  @observable indexMedia = 0

  htmlElement = document.getElementsByTagName('html')[0]

  constructor() {
    reaction(
      () => this.openPostMediasDetail,
      async (postDetailDialog) => {
        this.toggleOverflowMainLayer(postDetailDialog)
      },
      {
        fireImmediately: true,
      }
    )
  }

  @asyncAction *init(postStore: PostStore) {
    try {
      this.postStore = postStore
      this.openPostMediasDetail = true
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action toggleOverflowMainLayer(postDetailDialog) {
    if (this.htmlElement && this.htmlElement.style) {
      if (postDetailDialog) {
        this.htmlElement.style.height = '100%'
        this.htmlElement.style.overflow = 'hidden'
      } else {
        this.htmlElement.style.overflow = 'auto'
      }
    }
  }

  @action changeOpenPostMediasDetail(forceHide = false, value, mediaId = -1) {
    if (forceHide && value) return
    if (value) {
      const index = this.medias.findIndex((item) => item._id === mediaId)
      if (index === -1) return
      this.indexMedia = index
    }
    if (!value) this.postStore = undefined
    this.openPostMediasDetail = value
  }

  @action nextMedia(index) {
    if (index === 1) {
      if (this.indexMedia + 1 >= this.medias.length) {
        this.indexMedia = 0
      } else {
        this.indexMedia = this.indexMedia + 1
      }
    } else {
      if (this.indexMedia - 1 < 0) {
        this.indexMedia = this.medias.length - 1
      } else {
        this.indexMedia = this.indexMedia - 1
      }
    }
  }

  @computed get medias() {
    return this.postStore?.medias || []
  }

  @computed get contentBlocks() {
    return this.postStore?.contentBlocks
  }
  @computed get contentMedias() {
    return this.postStore?.contentMedias
  }

  @computed get displayedMedia() {
    return this.medias[this.indexMedia]
  }

  @computed get isDisplayedImage() {
    return this.displayedMedia?.mime?.includes('image')
  }
  @computed get isDisplayedVideo() {
    return this.displayedMedia?.mime?.includes('video')
  }
  @computed get post() {
    return this.postStore?.post
  }
}

export const postMediasDetailStore = new PostDetail()
