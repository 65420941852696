import { walletStore } from '@/stores/wallet-store'
import Vue from 'vue'

export const directiveRegister = () => {
  Vue.directive('wcg-click', (el, binding, vnode) => {
    vnode.componentInstance?.$on('click', function () {
      if (!walletStore.walletConnected || (!walletStore.isRegisted || !walletStore.isLogin)) {
        walletStore.setShowRequireLoginDialog(true)
      } else {
        binding.value()
      }
    })
  })
}