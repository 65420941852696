import { PostModel } from '@/models/post-model'
import { dispatcher, PostActionEnum } from '@/stores/dispatcher'
import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import { apiService } from '../api-services'
import { ApiRouteType } from '../types'

export class PostHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}
  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
      },
    })
    return res.data
  }

  async fetchPlaceDetail(params?: any): Promise<number> {
    const { fields, placeId } = params
    const formatedFields = fields.reduce(
      (previousValue, currentValue, currentIndex) =>
        previousValue + currentValue + (currentIndex === fields.length - 1 ? '' : '%2C'),
      ''
    )
    const res = await this.axios.get('/get-place-detail', {
      params: {
        fields: formatedFields,
        placeId,
      },
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, { params })
    const lst = res.data
    return lst
  }

  async fetchPosts<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: 1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const jwt = localData.getAccessToken()
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`fetchPosts`, {
      params,
      headers,
    })
    const lst = res.data
    const postLinks = lst.filter((post) => post.type === 'link')
    const promises: Promise<any>[] = []
    postLinks.map((post) => promises.push(apiService.posts.getOpenGraphInfo(post.link)))
    const openGraphDataList = await Promise.all(promises)
    let correctLst = [] as any
    if (!jwt) correctLst = lst.filter((item) => !item.isBlind)
    else correctLst = lst.filter((item) => !(item.isBlind && item.profile.id !== walletStore.userProfile?._id))
    return correctLst.map((post) => {
      let openGraphData
      if (post.type === 'link') openGraphData = openGraphDataList.find((item) => item.requestUrl === post.link) || {}
      return { ...post, openGraphData }
    })
  }

  async getPosts(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}) {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: 1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const jwt = localData.getAccessToken()
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`/get-posts`, {
      params,
      headers,
    })

    let lst = res.data.data
    const total = res.data.total
    const postLinks = lst.filter((post) => post.type === 'link')
    const promises: Promise<any>[] = []
    postLinks.map((post) => promises.push(apiService.posts.getOpenGraphInfo(post.link)))
    const openGraphDataList = await Promise.all(promises)
    lst = lst.filter((item) => !item.isBlind)
    return {
      data: lst.map((post) => {
        let openGraphData
        if (post.type === 'link') openGraphData = openGraphDataList.find((item) => item.requestUrl === post.link) || {}
        return { ...post, openGraphData }
      }),
      total: total,
    }
  }

  async fetchMyFeed<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<{ posts: T[]; total: number }> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: 1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const jwt = localData.getAccessToken()
    const headers = { ...this.axios.defaults.headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`/my-feed`, {
      params,
      headers,
    })
    let lst = res.data?.posts || ([] as any)
    lst = lst.filter((item) => !(item.isBlind && item.profile.id !== walletStore.userProfile?._id))
    const postLinks = lst.filter((post) => post.type === 'link')
    const promises: Promise<any>[] = []
    postLinks.map((post) => promises.push(apiService.posts.getOpenGraphInfo(post.link)))
    const openGraphDataList = await Promise.all(promises)
    return {
      posts: lst.map((post) => {
        let openGraphData
        if (post.type === 'link') openGraphData = openGraphDataList.find((item) => item.requestUrl === post.link) || {}
        return { ...post, openGraphData }
      }),
      total: res.data?.total || 0,
    }
  }

  async fetchDraftPosts<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: 1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const jwt = localData.getAccessToken()
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`fetchDraftPosts`, {
      params,
      headers,
    })
    const lst = res.data
    const postLinks = lst.filter((post) => post.type === 'link')
    const promises: Promise<any>[] = []
    postLinks.map((post) => promises.push(apiService.posts.getOpenGraphInfo(post.link)))
    const openGraphDataList = await Promise.all(promises)
    return lst.map((post) => {
      let openGraphData
      if (post.type === 'link') openGraphData = openGraphDataList.find((item) => item.requestUrl === post.link) || {}
      return { ...post, openGraphData }
    })
  }

  async createPost(post: PostModel | any) {
    if (!walletStore.userProfile?._id) throw 'Profile id is invalid'
    const res = await this.axios.post(`posts/create`, post, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    dispatcher.$postChanged.next({
      owner: walletStore.userProfile._id,
      type: PostActionEnum.create,
      daoId: post?.daoId,
    })
    return res.data
  }

  async publishDraftPost(data: { postId?: string }) {
    const res = await this.axios.post(`/publishDraftPost`, data, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async updatePost(model?: any): Promise<T> {
    if (!walletStore.userProfile?._id) throw 'Profile id is invalid'
    const res = await this.axios.post(`posts/update-post`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    dispatcher.$postChanged.next({
      owner: walletStore.userProfile._id,
      type: PostActionEnum.modify,
      daoId: model?.daoId,
    })
    return res.data
  }

  async deletePost(postId: any, daoId: any): Promise<T> {
    if (!walletStore.userProfile?._id) throw 'Profile id is invalid'
    const res = await this.axios.post(
      `delete-post`,
      { postId },
      {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      }
    )

    dispatcher.$postChanged.next({
      owner: walletStore.userProfile._id,
      type: PostActionEnum.delete,
      daoId: daoId,
    })

    return res.data
  }

  async like(model: T): Promise<T> {
    const res = await this.axios.post('like', model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    dispatcher.$upvoteCreated.next({
      like: res.data,
    })
    return res.data
  }

  async unlike(model: T): Promise<T> {
    const res = await this.axios.post('unlike', model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async translate(model: any): Promise<any> {
    const res = await this.axios.post('/translate-text', model)
    return res.data
  }

  async getOpenGraphInfo(url: string) {
    const urlEncode = encodeURIComponent(url)
    const res = await this.axios.get(`getOpenGraphInfo?url=${urlEncode}`)
    return res.data
  }

  async upload(file: Blob) {
    const formData = new FormData()
    formData.append('files', file)
    const res = await this.axios.post(`upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  }

  async getHotTags<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const jwt = localData.getAccessToken()
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }

    const res = await this.axios.get('get-hot-tags', {
      params,
      headers,
    })
    const lst = res.data
    return lst
  }

  async postViewIncrement(postId: string): Promise<T> {
    const res = await this.axios.post(`/increment/${postId}`)
    return res.data
  }

  async searchPosts<T>(text: string): Promise<T[]> {
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    const jwt = localData.getAccessToken()
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`/search/post?text=${text}`, { params: {}, headers })
    return res.data
  }

  async searchReviews<T>(text: string): Promise<T[]> {
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    const jwt = localData.getAccessToken()
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`/search/post?text=${text}&review=true`, { params: {}, headers })
    return res.data
  }

  async updateBlindState<T>(model: { status: boolean; reason?: string; id?: string }): Promise<T[]> {
    if (!model.id) throw 'post id is udnefined'
    const res = await this.axios.post(`/posts/update-blind-state`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })

    dispatcher.$blindPostChanged.next({
      status: model.status,
      postId: model.id,
    })

    return res.data
  }

  async createPostWithCaptcha(post: PostModel | any) {
    if (!walletStore.userProfile?._id) throw 'Profile id is invalid'
    const res = await this.axios.post(`posts/create`, post, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    dispatcher.$postChanged.next({
      owner: walletStore.userProfile._id,
      type: PostActionEnum.create,
      daoId: post?.daoId,
    })
    return res.data
  }
}
