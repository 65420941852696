import { uniq } from 'lodash'

export class LocalData {
  setWithExpiry(key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

  setWalletSelected(walletSelected: string) {
    if (walletSelected) {
      localStorage.setItem('walletSelected', walletSelected)
    } else {
      localStorage.removeItem('walletSelected')
    }
  }

  getActiveWalletAddress() {
    return localStorage.getItem('activeWalletAddress') || ''
  }

  setConnectYoutubeFlag(value = false) {
    if (value) localStorage.setItem('connectYoutubeFlag', 'ok')
    else localStorage.removeItem('connectYoutubeFlag')
  }

  getConnectYoutubeFlag() {
    return localStorage.getItem('connectYoutubeFlag') || false
  }

  getFirstLoginState() {
    return localStorage.getItem('isFirstLogin') || false
  }
  setFirstLoginState(value = false) {
    if (value) {
      localStorage.setItem('isFirstLogin', 'ok')
    } else {
      localStorage.removeItem('isFirstLogin')
    }
  }

  setActiveWalletAddress(walletAddress: string) {
    if (walletAddress) {
      localStorage.setItem('activeWalletAddress', walletAddress)
    } else {
      localStorage.removeItem('activeWalletAddress')
    }
  }

  getAccessToken() {
    return this.getWithExpiry('thinkin-accessToken') || ''
  }

  setAccessToken(walletAddress: string) {
    if (walletAddress) {
      this.setWithExpiry('thinkin-accessToken', walletAddress, 25 * 24 * 60 * 60000)
    } else {
      localStorage.removeItem('thinkin-accessToken')
    }
  }

  getWalletSelected() {
    return localStorage.getItem('walletSelected') || ''
  }

  setLastSeen(lastSeen: string) {
    if (lastSeen) {
      localStorage.setItem('lastSeen', lastSeen)
    } else {
      localStorage.removeItem('lastSeen')
    }
  }

  get lastSeen() {
    return localStorage.getItem('lastSeen')
  }

  get walletConnect() {
    return localStorage.getItem('walletconnect')
  }

  removeWalletConnect() {
    localStorage.removeItem('walletconnect')
  }

  get lastSearchs() {
    return JSON.parse(localStorage.getItem('lastSearchs') || '[]')
  }

  addLastSearch(key) {
    localStorage.setItem('lastSearchs', JSON.stringify(uniq([key, ...this.lastSearchs]).slice(0, 10)))
    return this.lastSearchs
  }

  removeLastSearch(key) {
    localStorage.setItem(
      'lastSearchs',
      JSON.stringify(uniq([...this.lastSearchs].filter((x) => x !== key)).slice(0, 10))
    )
    return this.lastSearchs
  }

  setLinkEmail2Wallet(obj) {
    if (!obj) {
      localStorage.removeItem('LinkEmail2Wallet')
      return
    }
    const { walletAddress, provider } = obj
    localStorage.setItem('LinkEmail2Wallet', JSON.stringify({ walletAddress, provider }))
  }

  getLinkEmail2Wallet() {
    const { walletAddress, provider } = JSON.parse(localStorage.getItem('LinkEmail2Wallet') || '{}')
    return { walletAddress, provider }
  }

  setTargetRouter(router) {
    if (!router) {
      localStorage.removeItem('targetRouter')
      return
    }
    localStorage.setItem('targetRouter', router)
  }

  getTargetRouter() {
    return localStorage.getItem('targetRouter') || ''
  }

  setRef(ref: string) {
    if (!ref) {
      localStorage.removeItem('ref')
      return
    }
    localStorage.setItem('ref', ref)
  }

  getRef() {
    return localStorage.getItem('ref') || ''
  }
}

export const localData = new LocalData()
