import { localData } from '@/stores/local-data'
import { ApiRouteType } from '../types'

export class PollHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async findOne<T>(id: any): Promise<T> {
    if (id == undefined) return {} as any
    const res = await this.axios.get(`${this.route}/${id}`, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }
}
