import { PostActionEnum, PostActionType } from '@/stores/dispatcher'

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = [] as any

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export const objIsNotEmpty = (obj) => {
  return obj == 0 || !!obj
}

export const getFollowStatsNumberAfterSync = (
  currentNumber: number | undefined,
  state: boolean,
  defaultValue: number | undefined = undefined
) => {
  if (currentNumber == undefined) return defaultValue
  const expectValue = state ? currentNumber + 1 : currentNumber - 1
  return expectValue >= 0 ? expectValue : 0
}

export const getPostStatsNumberAfterSync = (
  currentNumber: number | undefined,
  state: PostActionType,
  defaultValue: number | undefined = undefined
) => {
  if (currentNumber == undefined) return defaultValue
  const expectValue =
    state === PostActionEnum.create
      ? currentNumber + 1
      : state === PostActionEnum.delete
      ? currentNumber - 1
      : currentNumber
  return expectValue >= 0 ? expectValue : 0
}

export const formatStringNoExtraSpace = (s) => {
  if (!s) return ''
  return s.replace(/\s+/g, ' ').trim()
}
