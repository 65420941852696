/* eslint-disable */
import moment from 'moment'
import Vue from 'vue'
import { get, isNumber, isEmpty, round, toNumber } from 'lodash-es'
import { numberHelper } from '@/helper/number.hepler'
import { FixedNumber } from '@ethersproject/bignumber'

export const vueFilterRegister = () => {
  Vue.filter('date', (isoStr: string, format: string) => (isoStr ? moment(isoStr).format(format) : ''))
  Vue.filter('datetime', (isoStr: string, format = 'lll') => (isoStr ? moment(isoStr).format(format) : ''))
  Vue.filter('ddmmyyyy2', (isoStr: string) => (isoStr ? moment(isoStr).format('DD-MM-YYYY') : ''))
  Vue.filter('yyyymmdd', (isoStr: string) => (isoStr ? moment(isoStr).format('YYYY-MM-DD') : ''))
  Vue.filter('yyyymmddhhmm', (isoStr: string) => (isoStr ? moment(isoStr).format('YYYY/MM/DD HH:mm') : ''))
  Vue.filter('ddmmyyyy', (isoStr: string) => (isoStr ? moment(isoStr).format('DD/MM/YYYY') : ''))
  Vue.filter('mmddyyyy', (isoStr: string) => (isoStr ? moment(isoStr).format('MM/DD/YYYY') : ''))
  Vue.filter('yyyy.mm.dd', (isoStr: number) => (isoStr ? moment(isoStr).format('YYYY.MM.DD') : ''))
  Vue.filter('ddmmyyyyhhmm', (isoStr: number) => (isoStr ? moment(isoStr).format('DD/MM/YYYY HH:mm') : ''))
  Vue.filter('ddmmyyyyhhmmss', (isoStr: string) => (isoStr ? moment(isoStr).format('DD/MM/YYYY HH:mm:ss') : ''))
  Vue.filter('ddmmyyyyesthhmm', (isoStr: string) =>
    isoStr ? moment(isoStr).format('YYYY-MM-DD ') + ' EST ' + moment(isoStr).format('HH:mm') : ''
  )
  Vue.filter('mmhh', (isoStr: number) => (isoStr ? moment(Math.round(isoStr * 1000)).format('mm:ss ') : ''))
  Vue.filter('formatDateFromUnix', (isoStr: number) => (isoStr ? moment.unix(isoStr).format('DD/MM/YYYY') : ''))
  Vue.filter('datetimeStandard', (isoStr: string) => (isoStr ? moment(isoStr).format('MMM D. HH:mm:ss A') : ''))
  Vue.filter('datetimeGMT', (isoStr: string) => (isoStr ? moment(isoStr).format('HH:mm, DD MMM YYYY [(GMT]Z[)]') : ''))
  Vue.filter('secondToMiniSecond', (i: number) => i * 1000)
  Vue.filter('timeFromNow', (v: string) => moment(v).fromNow())
  Vue.filter('timeFromNowCustom', (v: string) => {
    if (!v) return ''
    if (moment().diff(moment(v), 'hours') > 24) {
      return moment(v).format('MM/DD/YYYY')
    } else return moment(v).fromNow()
  })
  Vue.filter('timeAfterDuration', (originalTime, days, hours, minutes) => {
    const pollDuration = moment.duration({
      days: +days,
      hours: +hours,
      minutes: +minutes,
    })
    return moment(originalTime).add(pollDuration)
  })
  Vue.filter('round', (number: any, precision = 5) => {
    if (typeof number === 'number') return number ? round(number, precision) : '0'
    if (number instanceof FixedNumber) {
      const rouned = number.round(precision).toString()
      return rouned.endsWith('.0') ? rouned.replace('.0', '') : rouned
    }
    return number
  })
  Vue.filter('_get', (any: any, path: string, defaultValue = '') => {
    return get(any, path, defaultValue)
  })
  Vue.filter('_empty', (any: any) => {
    return !isNumber(any) && (!any || isEmpty(any))
  })
  Vue.filter('_hasValue', (any: any) => {
    return isNumber(any) || (any && !isEmpty(any))
  })

  Vue.filter('formatNumber', (number: any, maximumFractionDigits = 5, minimumFractionDigits = 2) => {
    const nf = new Intl.NumberFormat('en-US', {
      maximumFractionDigits,
      minimumFractionDigits:
        minimumFractionDigits > maximumFractionDigits ? maximumFractionDigits : minimumFractionDigits,
    })
    return nf.format(number)
  })
  Vue.filter('shortText', (account: string, breakpoint: string) => {
    if (!account) return ''
    if (breakpoint === 'sm') {
      return account.substring(0, 4) + '...' + account.substring(account.length - 4)
    } else {
      return account.substring(0, 15) + '...' + account.substring(account.length - 8)
    }
  })
  Vue.filter('separation', (number: string, type?: string) => {
    if (type === 'decimal') {
      return number.substring(number.indexOf('.'))
    } else {
      return number.substring(0, number.indexOf('.'))
    }
  })
  Vue.filter('decimalFormatNumber', (num: any, maxDecimal = 2) => {
    const mutiple = Math.pow(10, maxDecimal)
    if (num instanceof FixedNumber) {
      return Math.floor(num.toUnsafeFloat() * mutiple) / mutiple
    }
    if (num instanceof Number || num instanceof String || typeof num === 'string' || typeof num === 'number') {
      return Math.floor(parseFloat(`${num}`) * mutiple) / mutiple
    }
    return ''
  })
}

Vue.filter('twoDigits', (value: any) => {
  if (value < 0) {
    return '00'
  }
  if (value.toString().length <= 1) {
    return `0${value}`
  }
  return value
})

Vue.filter('usd', (number: any) => {
  return numberHelper.usdFormat(number)
})
Vue.filter('usdCustom', (number: any, min = 2, max = 8) => {
  return numberHelper.usdFormat(number, min, max)
})
Vue.filter('numberFormatShort', (number: any, precision = 0) => {
  if (number >= 1000) precision = 0
  const res = number ? round(number, precision) : 0
  if (number >= 1000) {
    return `${round(res / 1000, 1)}K`
  } else {
    return res
  }
})

Vue.filter('last4Character', (text: string) => {
  return text.substr(text.length - 4) || ''
})

Vue.filter('nftTypeFormat', (text: string) => {
  let type = ''
  switch (text) {
    case 'top':
      type = 'Top'
      break
    case 'bottom':
      type = 'Bottom'
      break
    case 'face_accessory':
      type = 'Face Accessory'
      break
    case 'hair_accessory':
      type = 'Hair Accessory'
      break
    case 'shoe':
      type = 'Shoe'
      break
    case 'prop_accessory':
      type = 'Prop Accessory'
      break
    case 'background_accessory':
      type = 'Background Accessory'
      break
    case 'body':
      type = 'Body'
      break
    default:
      type = ''
      return
  }
  return type
})

Vue.filter('formatRctType', (text: 'find_my_team' | 'find_job' | 'find_hobby_buddy' | any, defaultValue = '') => {
  if (text === 'find_my_team') return 'Find my team'
  if (text === 'find_job') return 'Find job'
  if (text === 'find_hobby_buddy') return 'Find hobby buddy'
  return defaultValue
})

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']

function abbreviateNumber(number: number) {
  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0

  // if zero, we don't need a suffix
  if (tier == 0) return round(number, 1)

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier]
  const scale = Math.pow(10, tier * 3)

  // scale the number
  const scaled = number / scale

  // format number and add suffix
  return `${round(scaled, 1)}${suffix}`
}
