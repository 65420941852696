import { localData } from '@/stores/local-data'
import { ApiRouteType } from '../types'

export class RecruitmentApplyHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async submitApply(model: {
    emailAddress: string
    phoneNumber: string
    introduction: string
    data?: any
    cvFile?: string
    post: string
  }) {
    const res = await this.axios.post(`${this.route}/submit-apply`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async updateStatus(id, status: 'applied' | 'pending' | 'rejected') {
    const res = await this.axios.post(
      `${this.route}/${id}/update-status`,
      { status },
      {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      }
    )
    return res.data
  }

  async findOneApply<T>(params: { postId: string; profileId: string }): Promise<T[]> {
    const res = await this.axios.get(this.route, {
      params: { _limit: 1, profile_eq: params.profileId, post_eq: params.postId },
      headers: { ...this.axios.defaults.headers, Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async fetchApplies<T>(postId): Promise<T[]> {
    const res = await this.axios.get(this.route, {
      params: { _limit: -1, post_eq: postId },
      headers: { ...this.axios.defaults.headers, Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }
}
