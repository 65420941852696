import { dispatcher, FollowTypeEnum } from "@/stores/dispatcher";
import { localData } from "@/stores/local-data";
import { walletStore } from "@/stores/wallet-store";
import { ApiRouteType } from "../types";

export class UserDaoHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:ASC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}), status: params?.status ? params.status : 'public' }
    const res = await this.axios.get(this.route, { params })
    const lst = res.data
    return lst
  }

  async addToFeed(data: { daoId: string; state: boolean }) {
    if (!walletStore.userProfile?._id) throw 'Profile is undefined'
    const { daoId, state } = data
    const res = await this.axios.post(
      `/add-to-feed`,
      { daoId: daoId, addToFeed: state },
      {
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      }
    )
    dispatcher.$profileChanged.next({
      from: walletStore.userProfile?._id,
      to: daoId,
      followState: state,
      type: FollowTypeEnum.dao,
    })
    return res.data
  }
  async memberVisitedDao(daoId: string) {
    const res = await this.axios.post(
      `/user-daos/member-visited-dao`,
      { daoId },
      {
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      }
    )
    return res.data
  }
}
