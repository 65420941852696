import { localData } from "@/stores/local-data";
import { ApiRouteType } from "../types";

export class NotificationHandler<T> {
    constructor(private axios: any, private route: ApiRouteType) {}
    async count(params?: any): Promise<number> {
      const res = await this.axios.get(`${this.route}/count`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
        },
      })
      return res.data
    }
    async fetchNotifications<T>(
      params?: any,
      settings: { _sort?: string; _limit?: number; _start?: number } = {}
    ): Promise<T[]> {
      const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
      params = { ...settingDefault, ...settings, ...(params ?? {}) }
      const res = await this.axios.get(`/fetch-notifications`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      })
      return res.data
    }
  
    async removeNotification(data: T): Promise<T> {
      const res = await this.axios.post('/remove-notification', data, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
      return res.data
    }
  
    async markAllRead(): Promise<T> {
      const res = await this.axios.post(
        '/mark-all-read-notifications',
        {},
        {
          headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
        }
      )
      return res.data
    }
  }