import { localData } from "@/stores/local-data"
import { ApiRouteType } from "../types"

export class BookmarkHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
      },
    })
    return res.data
  }

  async createBookmark(body: { postId: string }) {
    const res = await this.axios.post(`/createBookmark`, body, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async removeBookmark(body: { postId: string }) {
    const res = await this.axios.post(`/removeBookmark`, body, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async getBookmarkedPost<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(`/get-bookmarked-posts`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }
}
