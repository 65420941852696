


























































































import { walletStore } from '@/stores/wallet-store'
import { walletSettingController } from './wallet-setting-controller'
import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'wallet-setting-connect-dialog': () => import('./wallet-setting-connect-dialog.vue'),
  },
})
export default class extends Vue {
  controller = walletSettingController
  wallet = walletStore
}
