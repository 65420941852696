import { dispatcher } from '@/stores/dispatcher'
import { localData } from '@/stores/local-data'
import { ApiRouteType } from '../types'

export class DecoratedPopoHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, {
      params,
      headers: { ...this.axios.defaults.headers, Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    const lst = res.data
    return lst
  }

  async getPendingDecoratedPoPo<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    params = {}
    const res = await this.axios.get(`/pending-popo`, {
      params,
      headers: { ...this.axios.defaults.headers, Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    const lst = res.data
    return lst
  }

  async fetchDecoratedPopos() {
    const res = await this.axios.post(
      `/${this.route}/fetchDecoratedPopos`,
      {},
      {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      }
    )
    return res.data
  }

  async mintPoPo(model) {
    const res = await this.axios.post(`/${this.route}/claimPoPo`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async claimPopo(model) {
    const res = await this.axios.post(`/${this.route}/set-claimed-popo`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async attachNft(model) {
    const res = await this.axios.post(`/${this.route}/attach`, model, {
      headers: { ...this.axios.defaults.headers, Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }

  async detachNft(model) {
    const res = await this.axios.post(`/${this.route}/detach`, model, {
      headers: {
        ...this.axios.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }
}
