import { ApiRouteType } from "../types";

export class LinkHandler<T> {
    constructor(private axios: any, private route: ApiRouteType) {}
    async fetchTopLinks<T>(
      params?: any,
      settings: { _sort?: string; _limit?: number; _start?: number } = {}
    ): Promise<T[]> {
      const settingDefault = { _sort: 'views:DESC', _limit: 3, _start: 0 }
      params = { ...settingDefault, ...settings, ...(params ?? {}) }
      const res = await this.axios.get(this.route, { params })
      const lst = res.data
      return lst
    }
  
    async increaseLinkView(body: { postId: string; link: string }) {
      const res = await this.axios.post(`/increase-link-view`, body)
      return res.data
    }
  }