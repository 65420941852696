import { FixedNumber } from '@ethersproject/bignumber'
import { findLast } from 'lodash-es'
export const Zero = FixedNumber.from(0)
export const FULL_100 = FixedNumber.from(100)
export const MB_TO_BYTE = 1024 * 1024
// max size upload for posting = 500MB
export const MAX_SIZE_IMAGE_UPLOAD_BY_MB = 500
export const MAX_SIZE_IMAGE_UPLOAD_BY_BYTE = MB_TO_BYTE * MAX_SIZE_IMAGE_UPLOAD_BY_MB

export const SOCIAL_SELECTORS = [
  {
    url: require(`@/assets/icons/share-link/facebook.svg`),
    name: 'Facebook',
    uniqName: 'facebook',
  },
  {
    url: require(`@/assets/icons/twitter-colorful-icon.svg`),
    name: 'Twitter',
    uniqName: 'twitter',
  },
  {
    url: require(`@/assets/icons/telegram-colorfull-icon.svg`),
    name: 'Telegram',
    uniqName: 'telegram',
  },
  {
    url: require(`@/assets/icons/linkedin-colorfull-icon.svg`),
    name: 'LinkedIn',
    uniqName: 'linkedin',
  },
  {
    url: require(`@/assets/icons/youtube-colorfull-icon.svg`),
    name: 'Youtube',
    uniqName: 'youtube',
  },
  // {
  //   url: require(`@/assets/icons/other-icon.svg`),
  //   name: 'Other',
  //   uniqName: 'other',
  // },
]

export const CONNECT_USER_SOCIALS = [
  'discord',
  'twitter',
  'github',
  'medium',
  'reddit',
  'tiktok',
  'youtube',
  'facebook',
]

const lst = [
  {
    amount: 0,
    period: 0,
    text: 'None Tier',
    tierIndex: 0,
    goldenTickeNumber: 0,
  },
  {
    amount: 1000,
    period: 0,
    text: 'TIER 1',
    tierIndex: 1,
    goldenTickeNumber: 1,

    ticketNumber: 4,
  },
  {
    amount: 5000,
    period: 0,
    text: 'TIER 2',
    tierIndex: 2,
    goldenTickeNumber: 2,
    ticketNumber: 8,
  },
  {
    amount: 10000,
    period: 30,
    text: 'TIER 3',
    tierIndex: 3,
    goldenTickeNumber: 3,
    ticketNumber: 12,
  },
  {
    amount: 25000,
    period: 40,
    text: 'TIER 4',
    tierIndex: 4,
    goldenTickeNumber: 5,
    ticketNumber: 15,
  },
  {
    amount: 50000,
    period: 50,
    text: 'TIER 5',
    tierIndex: 5,
    goldenTickeNumber: 10,
    ticketNumber: 20,
  },
]

export const DEFAULT_REVIEW_CATEGORIES = [
  { id: '63468c0ca5bdf1f0950d3ea0', label: 'Education', type: 'education' },
  { id: '63468abfe42d19f004571982', label: 'Travel', type: 'travel' },
  { id: '63468ba5fa6379f05958a4e9', label: 'Deep-fi', type: 'deep-fi' },
  { id: '63468abfe42d19f004571984', label: 'NFT Lover', type: 'nft-lover' },
  { id: '63468abfe42d19f004571985', label: 'Writing', type: 'writing' },
  { id: '63468abfe42d19f004571986', label: 'Sports', type: 'sports' },
  { id: '63468abfe42d19f004571987', label: 'Gaming', type: 'gaming' },
  { id: '63468abfe42d19f004571988', label: 'Crypto', type: 'crypto' },
  { id: '63468abfe42d19f004571989', label: 'Technology', type: 'technology' },
  { id: '63468abfe42d19f00457198a', label: 'Music', type: 'music' },
  { id: '63468abfe42d19f00457198b', label: 'Social Media', type: 'social-media' },
  { id: '63468abfe42d19f00457198c', label: 'Food', type: 'food' },
  { id: '63468abfe42d19f00457198d', label: 'Business', type: 'business' },
  { id: '63468abfe42d19f00457198e', label: 'X2E', type: 'x2e' },
  { id: '63468abfe42d19f00457198f', label: 'Startup', type: 'startup' },
  { id: '63468abfe42d19f004571990', label: 'Towards Data Science', type: 'towards-data-science' },
  { id: '63468abfe42d19f004571991', label: 'Personal Growth', type: 'personal-growth' },
  { id: '63468abfe42d19f004571992', label: 'BetterHumans', type: 'better-humans' },
  { id: '63468abfe42d19f004571993', label: 'The Economist', type: 'the-economist' },
  { id: '63468abfe42d19f004571994', label: 'Better Marketing', type: 'better-marketing' },
  { id: '63468abfe42d19f004571995', label: 'Health', type: 'health' },
  { id: '63468abfe42d19f004571996', label: 'Design', type: 'design' },
  { id: '63468abfe42d19f004571997', label: 'Jounalism', type: 'jounalism' },
  { id: '63468abfe42d19f004571998', label: 'Art', type: 'art' },
]
export const DEFAULT_POLL_VOTES = [
  {
    id: 0,
    label: 'Option 1',
    textInput: '',
    error: '',
  },
  {
    id: 1,
    label: 'Option 2',
    textInput: '',
    error: '',
  },
]

export const DEFAULT_AUDIENCE_DETAILS = [
  {
    id: 'general',
    title: 'General',
  },
]

export const MINT_MEMBER_TYPE = {
  public: 0,
  whitelist: 1,
}
