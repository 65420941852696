import Vue from 'vue'

export const componentRegister = (): void => {
  Vue.component('checkbox-circle', () => import('@/modules/common/components/form/checkbox-circle.vue'))
  Vue.component('update-button', () => import('@/components/update-button.vue'))
  Vue.component('post-card', () => import('@/components/post-card.vue'))
  Vue.component('tag', () => import('@/components/tag.vue'))
  Vue.component('tab-controller', () => import('@/components/tab-controller.vue'))
  Vue.component('search', () => import('@/components/search/search.vue'))
  Vue.component('avatar', () => import('@/modules/community/common/components/avatar.vue'))
  Vue.component('avatar-post', () => import('@/modules/community/common/components/avatar-post.vue'))
  Vue.component('group-cover', () => import('@/modules/community/common/components/group-cover.vue'))
  Vue.component('c-select', () => import('@/components/c-select.vue'))
  Vue.component('c-tabs', () => import('@/components/c-tabs.vue'))
  Vue.component('tag-category', () => import('@/components/tag-category.vue'))
  Vue.component('nothing-type-1', () => import('@/components/nothings/nothing-type-1.vue'))
  Vue.component('nothing-type-2', () => import('@/components/nothings/nothing-type-2.vue'))
  Vue.component('nothing-type-3', () => import('@/components/nothings/nothing-type-3.vue'))
  Vue.component('notification', () => import('@/components/notification.vue'))
  Vue.component('wallet-connect-guard', () => import('@/components/auth/wallet-connect-guard.vue'))
  Vue.component(
    'dao-admin-guard',
    () => import('@/modules/community-organize/components/dao-detail-setting/components/dao-admin-guard.vue')
  )
  Vue.component('post-list-item', () => import('@/modules/community/common/components/post-list-item.vue'))
  Vue.component('v-tooltip', () => import('@/components/app-tooltip.vue'))
  Vue.component(
    'comment-translate-button',
    () => import('@/modules/community/common/components/comment-translate-button.vue')
  )
  Vue.component(
    'comment-delete-button',
    () => import('@/modules/community/common/components/comment-delete-button.vue')
  )
  Vue.component('popo-image', () => import('@/modules/popo/components/popo-image.vue'))
  Vue.component('popo-card', () => import('@/modules/popo/components/popo-card.vue'))
  Vue.component('attach-nft-card', () => import('@/modules/popo/components/attachs/attach-nft-card.vue'))
  Vue.component('popo-list', () => import('@/modules/popo/components/popo-list.vue'))
  Vue.component('rct-type-chip', () => import('@/modules/recruitment/components/rct-type-chip.vue'))
  Vue.component('rct-due-date', () => import('@/modules/recruitment/components/rct-due-date.vue'))
  Vue.component('rct-due-date-no-color', () => import('@/modules/recruitment/components/rct-due-date-no-color.vue'))
  Vue.component('rct-no-chip', () => import('@/modules/recruitment/components/rct-no-chip.vue'))
  Vue.component('rct-no-job-chip', () => import('@/modules/recruitment/components/rct-no-job-chip.vue'))
  Vue.component('rct-social-list', () => import('@/modules/recruitment/components/rct-social-list.vue'))
  Vue.component('upload-pdf-drag-drop', () => import('@/modules/recruitment/components/upload-pdf-drag-drop.vue'))
  Vue.component('rct-post-content', () => import('@/modules/recruitment/components/rct-post-content.vue'))

  // Vue.component('navigation-bar', () => import('@/components/navigation-bar.vue'))
  // Vue.component('mobile-navigation-bar', () => import('@/components/mobile-navigation-bar.vue'))
  // Vue.component('navigation-drawer', () => import('@/components/navigation-drawer.vue'))
  // Vue.component('page-footer', () => import('@/components/page-footer.vue'))
}
