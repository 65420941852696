import { promiseHelper } from '@/helper/promise-helper'
import { UserModel } from '@/models/user-model'
import { rules } from '@/plugins/rules'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import _ from 'lodash'
import { action, computed, observable, reaction, toJS } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { snackController } from '../snack-bar/snack-bar-controller'

class AccountSettingController {
  @observable isOpenDialog = false
  @observable confirming = false

  @observable emailAddress = ''
  @observable username = ''

  @observable registed = false

  // CONFIRM
  @action.bound onEmailAddressChange(val = '') {
    this.emailAddress = val
  }

  @action.bound onUsernameChange(val = '') {
    this.username = val
  }

  @action reset() {
    this.onEmailAddressChange()
    this.onUsernameChange()
  }

  @asyncAction *confirm() {
    try {
      this.confirming = true
      // if (this.emailAddress) {
      //   if (rules.email(this.emailAddress) === true) {
      //     if (this.emailAddress !== walletStore.userProfile?.email) {
      //       const userEmail = yield apiService.users.find({ email: this.emailAddress })
      //       if (userEmail.length) {
      //         snackController.error("Email is existed")
      //         return
      //       }
      //     }
      //   } else {
      //     snackController.error(`"${this.emailAddress}" is not email`)
      //     return
      //   }
      // }
      if (this.registed) {
        yield walletStore.updateUserProfile({
          id: walletStore.userProfile?._id,
          username: this.username,
          linkUser: this.linkUser,
        })
      } else {
        yield walletStore.createUserProfile({ userName: this.username, linkUser: this.linkUser })
      }
      this.confirming = false
      this.setOpenDialog(false)
    } catch (error) {
      snackController.commonError(error)
      const errMsg = _.get(error, 'response.data.message') || JSON.stringify(error)
      if (errMsg.includes('already has profile')) {
        this.linkUser = undefined
      }
    } finally {
      this.confirming = false
    }
  }

  @observable linkUser?: { jwt: string; user: UserModel } = undefined
  @computed get linkUserProvider() {
    const profile = walletStore.userProfile
    if (profile?.apple_user) return 'apple'
    if (profile?.google_user) return 'google'
    return this.linkUser?.user?.provider
  }
  @observable allowEditEmail = false

  // DIALOG
  @action.bound setOpenDialog(val: boolean, linkUser: { jwt: string; user: UserModel } | undefined = undefined) {
    if (val === this.isOpenDialog) return
    const profileInfo = walletStore.userProfile
    if (this.isOpenDialog && !val) {
      // if has not register => disable close dialog
      if (!profileInfo) {
        return
      }
    }
    // if (linkUser?.user) {
    //   const linkProfile =
    // }
    this.linkUser = linkUser
    console.log("=======this.linkUser", this.linkUser)
    this.username = walletStore.userProfile?.username || ''

    this.allowEditEmail = !profileInfo?.apple_user && !profileInfo?.google_user
    if (this.allowEditEmail) {
      this.emailAddress = linkUser?.user?.email || ''
    } else {
      this.emailAddress = walletStore.userProfile?.email || ''
    }

    if (!this.username) {
      this.username = linkUser?.user?.username || ''
      const pref = `${linkUser?.user?.provider}___`
      if (this.username.startsWith(pref)) {
        this.username = this.username.substring(pref.length)
      }
    }

    this.registed = !!walletStore.userProfile
    if (this.confirming) return
    this.isOpenDialog = val
  }
}

export const accountSettingController = new AccountSettingController()
