import { action, computed, observable, reaction, runInAction } from 'mobx'
import moment from 'moment'
import { timer } from 'rxjs'
import VueRouter from 'vue-router'

export class AppProvider {
  router!: VueRouter

  @observable currentTime = moment()
  @observable drawerDialog = false

  @observable showNotifyOnMainPage = true

  constructor() {
    timer(0, 1000).subscribe(() => {
      runInAction(() => {
        this.currentTime = moment().milliseconds(0)
      })
    })
  }

  @action changeDrawerDialog(value) {
    this.drawerDialog = value
  }
}

export const appProvider = new AppProvider()
