import { localData } from '@/stores/local-data'
import { ApiRouteType } from '../types'

export class VoteHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}
  async vote(model): Promise<T> {
    //pollId, voteOption
    const res = await this.axios.post(`poll-vote`, model, {
      headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
    })
    return res.data
  }
}
