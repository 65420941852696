import { action, autorun, computed, observable, reaction, runInAction, toJS } from 'mobx'
import { asyncAction } from 'mobx-utils'
import MyAlgoConnect from '@randlabs/myalgo-connect'
import { localData } from './local-data'
import algosdk, { Algodv2, Transaction } from 'algosdk'
import { apiService } from '@/services/api-services'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { encode, decode } from 'uint8-to-base64'
import WalletConnect from '@walletconnect/client'
import WalletConnectProvider from "@walletconnect/web3-provider";
import QRCodeModal from 'algorand-walletconnect-qrcode-modal'
import { UserModel } from '@/models/user-model'
import { appProvider } from '@/app-provider'
import { algoClient } from '@/algo-client'
import { ProfileModel } from '@/models/profile-model'
import moment from 'moment'
import { get } from 'lodash'
import { accountSettingController } from '@/components/auth/account-setting-controller'
import { walletSettingController } from '@/components/auth/wallet-setting-controller'
import { promiseHelper } from '@/helper/promise-helper'
import { dispatcher } from './dispatcher'
import { FractalUserModel } from '@/models/fractal-user-model'
import { PeraWalletConnect } from '@perawallet/connect'
import { loadingController } from '@/components/global-loading/global-loading-controller'
import Application from '@/libs/models'
import { ethers } from 'ethers'
export interface KYCStatus {
  title: string
  subTitle: string
  btnText: string
}

export const connector = new WalletConnect({
  bridge: 'https://bridge.walletconnect.org',
  qrcodeModal: QRCodeModal,
})

export const peraWallet = new PeraWalletConnect({
  network: 'mainnet',
  chainId: 416001,
})
export class WalletStore {
  walletConnectProvider = new WalletConnectProvider({
    rpc: {
      10: 'https://mainnet.optimism.io',
    }
  } as any) as any
  ethereum: any = (window as any).ethereum
  app = new Application({ mainnet: true })

  @observable detailDialog = false
  @observable connectDialog = false
  @observable openKycWarningDialog = false

  @observable account = localData.getActiveWalletAddress()
  @observable selectedWallet = ''
  @observable showConnectDialog = false
  // @observable chainId = 4160
  @observable chainId = 10
  @observable userProfile?: ProfileModel = undefined
  @observable jwt = localData.getAccessToken()
  algodClient?: Algodv2 = undefined
  @observable openKycDialog = false
  @observable openEarnConnectWallet = false
  @observable openRequireActionConnectWallet = false
  @observable openRequireActionVerifyKyc = false
  @observable sessionId: any = ''
  @observable isLoadingSignIn = false
  @observable userInfo?: UserModel = undefined
  @observable fractalUser?: FractalUserModel = undefined
  @observable loading = false
  @observable connectingWallet = false
  @observable showRequireLoginDialog = false
  @observable notInWhitelist = false
  @observable loaded = false
  @observable recommendUsers: ProfileModel[] = []
  @observable isNewBie = false
  @observable isFirstLogin = false

  @observable defaultLanguage = undefined as any
  @observable defaultTranslate = 'Translate' as any

  constructor() {
    this.algodClient = algoClient

    autorun(() => {
      this.init(this.account, this.jwt)
      peraWallet.reconnectSession().then((accounts) => {
        if (accounts.length) {
          console.log('accounts', accounts)
        }
        peraWallet.connector?.on('disconnect', (error, payload) => {
          if (error) {
            throw error
          }
          if (payload.params[0]?.message === 'Session update rejected') snackController.error('User reject request')
          else this.disconnect()
        })
      })
    })

    const res = navigator.languages ? navigator.languages[0] : navigator.language
    this.defaultLanguage = res.substring(0.2)
    this.setTranslateText()
  }

  @asyncAction *setTranslateText() {
    try {
      const res = yield apiService.posts.translate({ text: 'Translate', target: this.defaultLanguage })
      this.defaultTranslate = res[0]
    } catch (e) {
      snackController.commonError(e)
    }
  }

  @asyncAction *init(account, jwt) {
    if (!account && !jwt) {
      this.loaded = true
      return
    }
    const hasUser = yield this.checkUserWallet()
    if (jwt) {
      this.isFirstLogin = !!localData.getFirstLoginState()
      this.checkNewBie()
      this.getUserFollowRecommends()
      localData.setLastSeen(moment().format('MMM,DD, YYYY'))
    }
    this.loaded = true
    if (account && !hasUser) {
      this.signUpWallet(account, false)
    }
    if (jwt && !this.userProfile) {
      yield promiseHelper.delay(1000)
      if (account) {
        accountSettingController.setOpenDialog(true)
      } else {
        walletSettingController.setOpenDialog(true)
      }
    }
  }

  @action checkNewBie() {
    if (this.userProfile) {
      const newBieTime = moment(this.userProfile.createdAt).add(3, 'days')
      this.isNewBie = moment().isBefore(newBieTime)
    }
  }

  @action changeUserProfile(obj) {
    this.userProfile = { ...this.userProfile, ...obj }
  }

  @action changeFirstLoginState(value) {
    localData.setFirstLoginState(value)
    this.isFirstLogin = value
  }

  @action.bound showDetailWalletDialog(value: boolean) {
    this.detailDialog = value
  }

  @action.bound showConnectWalletDialog(value: boolean) {
    this.connectDialog = value
  }

  async test() {
    // await this.blockChainHandler?.sendOptIn(this.account)
    // await this.blockChainHandler?.buy(this.account, 100000000)
  }

  @asyncAction *getUserFollowRecommends() {
    try {
      if (this.userProfile) {
        const res = yield apiService.userFollows.getUserFollowRecommends({})
        this.recommendUsers = res.map((item) => ({ ...item, value: item.username }))
      }
    } catch (e) {
      console.log('error getUserFollowRecommends', e)
    }
  }

  @action resetCache() {
    this.jwt = ''
    this.userInfo = undefined
    this.fractalUser = undefined
  }

  @asyncAction *start() {
    try {
      if (localData.getActiveWalletAddress) this.account = localData.getActiveWalletAddress()
      if (localData.getWalletSelected) this.selectedWallet = localData.getWalletSelected()
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action.bound changeShowConnectDialog(value: boolean) {
    this.showConnectDialog = value
  }

  @action.bound setShowRequireLoginDialog(value: boolean) {
    this.showRequireLoginDialog = value
  }

  @action.bound setNotInWhitelistDialog(value: boolean) {
    this.notInWhitelist = value
  }

  @asyncAction *connectMetamask() {
    loadingController.increaseRequest()
    try {
      const ok = yield this.app.login()
      if (ok) {
        this.account = yield this.app.getAddress()
        // this.chainId = yield this.app.getChainId()
        // console.log("======this.chainId", this.chainId)
        this.selectedWallet = 'metamask'
        localData.setWalletSelected('metamask')
        localData.setActiveWalletAddress(this.account)
        this.ethereum.removeListener('accountsChanged', this.ethereumConfigChanged)
        this.ethereum.removeListener('chainChanged', this.ethereumConfigChanged)
        this.ethereum.once('accountsChanged', this.ethereumConfigChanged)
        this.ethereum.once('chainChanged', this.ethereumConfigChanged)
      }
      this.showConnectWalletDialog(false)
      console.log("=========connectMetamask")
      this.signIn()
      return ok
    } catch (error) {
      snackController.commonError(error)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }

  async switchNetwork(chainId: string) {
      try {
        this.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xa' }]
        })
      } catch (error: any) {
        if (error.message.includes('wallet_addEthereumChain')) {
          // if (chainId === 'Oxa') {
            this.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0xa',
                  chainName: 'OP Mainnet',
                  nativeCurrency: {
                    name: 'Optimisimn',
                    symbol: 'OP',
                    decimals: 18
                  },
                  rpcUrls: [
                    'https://optimism.llamarpc.com',
                    'https://optimism.blockpi.network/v1/rpc/public',
                    'https://api.zan.top/node/v1/opt/mainnet/public'
                  ],
                  blockExplorerUrls: ['https://optimistic.etherscan.io/']
                }
              ]
            })
          // }
        }
      }
    
  }

  ethereumConfigChanged = () => {
    window.location.reload()
  };

  @asyncAction *connectMyAlgoWallet() {
    this.connectingWallet = true
    try {
      const myAlgoConnect = new MyAlgoConnect({ disableLedgerNano: false })
      const settings = {
        shouldSelectOneAccount: true,
        openManager: true,
      }
      const accounts = yield myAlgoConnect.connect(settings)
      if (accounts.length) {
        this.account = accounts[0]?.address
        this.selectedWallet = 'my-algo-wallet'
        localData.setWalletSelected('my-algo-wallet')
        localData.setActiveWalletAddress(this.account)
      }
      this.showConnectWalletDialog(false)
      console.log("=========CALL SignIn")
      this.signIn(false, 'algo')
    } catch (e) {
      //
      console.log(e)
    } finally {
      this.connectingWallet = false
    }
  }

  @asyncAction *connectAlgoSigner() {
    if (typeof (window as any).AlgoSigner !== 'undefined') {
      yield (window as any).AlgoSigner.connect()
      const accounts = yield (window as any).AlgoSigner.accounts({
        ledger: 'TestNet',
      })
      if (accounts.length) {
        this.account = accounts[0]?.address
        this.selectedWallet = 'algo-signer'
        localData.setWalletSelected('algo-signer')
        localData.setActiveWalletAddress(this.account)
      }
      this.showConnectWalletDialog(false)
      this.signIn()
    } else {
      ;(window as any)
        .open('https://chrome.google.com/webstore/detail/algosigner/kmmolakhbgdlpkjkcjkebenjheonagdm', '_blank')
        .focus()
    }
  }

  @action changeOpenKycDialog(value) {
    this.openKycDialog = value
  }
  @action changeOpenEarnConnectWallet(value) {
    this.openEarnConnectWallet = value
  }
  @action changeOpenRequireActionConnectWallet(value) {
    this.openRequireActionConnectWallet = value
  }
  @action changeOpenRequireActionVerifyKyc(value) {
    this.openRequireActionVerifyKyc = value
  }

  @action submitKyc() {
    if (this.fractalUser) window.open(process.env.VUE_APP_KYC_FRONTEND_DOMAIN, '_blank')
    else window.open(process.env.VUE_APP_KYC_FRACTAL_LINK, '_blank')
    this.openKycDialog = false
  }

  @asyncAction *connectWalletConnect() {
    try {
      loadingController.increaseRequest()
      console.log("========HERE")
      yield this.walletConnectProvider.enable()
      const walletConnect = localData.walletConnect ? localData.walletConnect : ''
      const walletConnectParsed = JSON.parse(walletConnect)
      this.account = walletConnectParsed.accounts[0]
      this.chainId = walletConnectParsed.chainId
      // this.web3 = new Web3(this.walletConnectProvider)
      this.walletConnectProvider.on('accountsChanged', (accounts: string[]) => {
        window.location.reload()
      })
      this.walletConnectProvider.on('chainChanged', (chainId: number) => {
        window.location.reload()
      })
      this.showConnectWalletDialog(false)
      this.signIn()
    } catch (error) {
      // error.message && snackController.error(error.message)
      return false
    } finally {
      loadingController.decreaseRequest()
    }
  }

  @asyncAction *connectViaWalletConnect() {
    try {
      if (!connector.connected) {
        connector.createSession()
      }
      if (connector.pending) {
        QRCodeModal.open(connector.uri, null)
      }
      connector.on('connect', (error, payload) => {
        runInAction(() => {
          this.account = payload?.params[0].accounts[0]
          this.selectedWallet = 'wallet-connect'
          localData.setActiveWalletAddress(this.account)
          this.showConnectWalletDialog(false)
          QRCodeModal.close()
          this.signIn()
        })
        if (error) {
          throw error
        }
      })

      connector.on('session_update', (error, payload) => {
        if (error) {
          throw error
        }
      })

      connector.on('disconnect', (error, payload) => {
        if (error) {
          throw error
        }
        if (payload.params[0]?.message === 'Session update rejected') snackController.error('User reject request')
        else this.disconnect()
      })
    } catch (error) {
      snackController.commonError(error)
    } finally {
      //
    }
  }

  @asyncAction *connectPeraWallet() {
    try {
      peraWallet.connect().then((newAccounts) => {
        peraWallet.connector?.on('disconnect', this.handleDisconnectWalletClick)
        runInAction(() => {
          this.account = newAccounts[0]
          this.selectedWallet = 'pera-wallet'
          localData.setActiveWalletAddress(this.account)
          this.showConnectWalletDialog(false)
          this.signIn(false, 'algo')
        })
      }) as any
      // .reject((error) => {
      //   // You MUST handle the reject because once the user closes the modal, peraWallet.connect() promise will be rejected.
      //   // For the async/await syntax you MUST use try/catch
      //   console.log("===========error", error)
      //   if (error?.data?.type !== 'CONNECT_MODAL_CLOSED') {
      //     // log the necessary errors
      //   }
      // })
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @action.bound handleDisconnectWalletClick() {
    peraWallet.disconnect()
  }

  @asyncAction *signMessageEVM(showError = true) {
    const user = yield apiService.users.find({ username: this.account })
    const nonce = user[0]?.nonce
    if (!user) return ''
    const message = `https://beta.thinkin.ink wants to: \n Sign message with account \n ${this.account} - One time nonce: ${nonce}`
    const data = new TextEncoder().encode(message)
    //bsc sign message
    if (typeof window === 'undefined') {
      return ''
    }
    if ((window as any).ethereum) {
      const request = { method: 'personal_sign', params: [message, this.account] }
      return yield (window as any).ethereum.request(request)
    } else {
      throw new Error('Plugin Metamask is not installed!')
    }
  }

  @asyncAction *signMessage(showError = true) {
    try {
      if (!this.account) return {}
      console.log("========SignMessage Algo")
      let user = yield apiService.users.find({ username: this.account })
      if (!user.length) {
        yield this.signUpWallet(this.account, false)
        user = yield apiService.users.find({ username: this.account })
      }
      console.log('user=', toJS(user))
      const nonce = user[0]?.nonce
      const message = `https://beta.thinkin.ink wants to: \n Sign message with account \n ${this.account} - One time nonce: ${nonce}`
      const encoder = new TextEncoder()
      const messageEncoded = encoder.encode(message)

      const params = yield this.algodClient?.getTransactionParams().do()
      const txn = algosdk.makePaymentTxnWithSuggestedParamsFromObject({
        suggestedParams: {
          ...params,
        },
        from: this.account,
        to: this.account,
        amount: 0,
        note: messageEncoded,
      })

      if (this.selectedWallet === 'algo-signer') {
        const txn_b64 = (window as any).AlgoSigner.encoding.msgpackToBase64(txn.toByte())
        const signedTxs = yield (window as any).AlgoSigner.signTxn([{ txn: txn_b64 }])
        return { signedTxn: signedTxs[0].blob, publicAddress: this.account }
      } else if (this.selectedWallet === 'my-algo-wallet') {
        const myAlgoConnect = new MyAlgoConnect()
        const signedTxns = yield myAlgoConnect.signTransaction(txn.toByte())
        const signedTxn = signedTxns.blob
        const signedTxnBase64 = encode(signedTxn)
        return { signedTxn: signedTxnBase64, publicAddress: this.account, nonce }
      } else if (this.selectedWallet === 'pera-wallet') {
        const txns = [txn]
        const txnsToSign = txns.map((txn) => {
          const encodedTxn = Buffer.from(algosdk.encodeUnsignedTransaction(txn)).toString('base64')
          return {
            txn: encodedTxn,
          }
        })
        const singleTxnGroups = [{ txn: txn, signers: [this.account] }]
        console.log('=====pera', peraWallet)
        const signedTxns = yield peraWallet.signTransaction([singleTxnGroups])
        const signedTxn = encode(signedTxns[0])
        if (!signedTxn) {
          this.disconnect()
          snackController.error('Have sign message error. Please try again!')
        }
        return { signedTxn: signedTxn, publicAddress: this.account, nonce }
      }
    } catch (error: any) {
      showError && snackController.commonError(error)
    }
  }

  @asyncAction *connectSocialAccount(provider: string, accessToken: string) {
    try {
      const { walletAddress, provider: linkToProvider } = localData.getLinkEmail2Wallet()
      const res = yield apiService.users.socialSignIn(provider, accessToken)
      const isBlocked = res.user.blocked
      if (isBlocked) {
        snackController.commonError('Your account has been blocked')
        return
      }
      const email = res.user.email
      console.log(
        { email, walletAddress, provider, linkToProvider },
        email && walletAddress && provider === linkToProvider
      )
      if (email && walletAddress && provider === linkToProvider) {
        accountSettingController.setOpenDialog(true, res)
        return
      }

      this.jwt = res.jwt

      // fix mobx issue => not change this

      localData.setFirstLoginState(res.user?.profile?.totalPosts === 0 ? true : false)
      this.isFirstLogin = !!localData.getFirstLoginState()

      localData.setAccessToken(res.jwt)
      localData.setLastSeen(moment().format('MMM,DD, YYYY'))

      if (res.user) {
        this.userInfo = { ...res.user }
      }

      if (res.user?.profile) {
        yield this.setFractalUser(res.user.profile.fractal_user)
        this.userProfile = { ...res.user?.profile }
      }
      //end
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *updateUserProfile(params) {
    const profile = yield apiService.profiles.updateUserProfile(params)
    this.userProfile = profile
    dispatcher.$profileChanged.next()
    return profile
  }

  @asyncAction *createUserProfile(params: {
    email?: string
    userName?: string
    walletJwt?: string
    signatureTx?: string
    linkUser?: any
  }) {
    const { email, userName, signatureTx, walletJwt, linkUser } = params
    try {
      this.loading = true
      // const signature = signatureTx || (yield this.signMessage())
      // if (!signature) {
      //   snackController.error('Sign messeage is invalid')
      //   return
      // }
      const register = yield apiService.profiles.createUserProfile({
        // ...signature,
        walletJwt,
        email,
        userName,
        linkUser,
      })
      this.userProfile = { ...register?.profile }
      this.jwt = register.jwt
      localData.setAccessToken(register.jwt)
      localData.setLastSeen(moment().format('MMM,DD, YYYY'))
      snackController.success('Register successfully')

      yield this.checkUserWallet()
      localData.setFirstLoginState(this.userProfile?.totalPosts === 0 ? true : false)
      this.checkNewBie()
      window.location.replace('/')
    } catch (error: any) {
      yield apiService.logs.create({error: "Client-createUserProfile", data: {error: error, email, userName}})
      snackController.commonError(error)
    } finally {
      this.loading = false
    }
  }

  @asyncAction *signIn(showError = true, type: 'algo' | 'metamask' = 'metamask') {
    try {
      this.isLoadingSignIn = true
      let signature;
      if(type === 'algo') signature = yield this.signMessage(showError)
      else signature = yield this.signMessageEVM(showError)
      let res
      console.log("=======signature", signature)
      if (signature) res = yield apiService.users.signIn({signature, publicAddress: this.account, type})
      if (res) {
        localData.setAccessToken(res.jwt)
        localData.setLastSeen(moment().format('MMM,DD, YYYY'))
        localData.setFirstLoginState(this.userProfile?.totalPosts === 0 ? true : false)
        this.jwt = res.jwt

        // fix mobx issue => not change this
        if (res.updatedUser) {
          this.userInfo = { ...res.updatedUser }
        }
        if (res.updatedUser?.profile) {
          this.userProfile = { ...res.updatedUser.profile }
          yield this.setFractalUser(res.updatedUser.profile.fractal_user)
        }
        
        if(appProvider.router?.currentRoute?.name !== "Change Wallet" && appProvider.router?.currentRoute?.name !== "Update Wallet" ) {
          appProvider.router.push('/')
        }
        //end
      } else {
        showError && this.disconnect()
      }
      return true
    } catch (error: any) {
      showError && snackController.commonError(error)
    } finally {
      this.isLoadingSignIn = false
    }
  }

  @asyncAction *signUpWallet(publicAddress, showError = true) {
    try {
      yield apiService.users.signUp({ publicAddress })
    } catch (error) {
      const apiError = get(error, 'response.data.message')
      const errId = get(apiError, "[0]['messages'][0]['id']")
      if (errId === 'Auth.form.error.publicAddress.notInWhitelist') {
        this.account = ''
        this.selectedWallet = ''
        localData.setWalletSelected('')
        localData.setActiveWalletAddress('')
        this.setShowRequireLoginDialog(false)
        this.setNotInWhitelistDialog(true)
      } else {
        showError && snackController.commonError(error)
      }
    }
  }

  @action disconnect() {
    localData.setAccessToken('')
    localData.setActiveWalletAddress('')
    localData.setWalletSelected('')
    localData.setAccessToken('')
    localData.removeWalletConnect()
    if (connector) {
      connector.killSession()
      connector.off('connect')
      connector.off('session_update')
      connector.off('disconnect')
    }
    window.location.replace('/')
  }
  

  @action logout() {
    localData.setAccessToken('')
    appProvider.router.push('/')
    window.location.reload()
  }

  @asyncAction *checkUserWallet() {
    try {
      let data: any = undefined
      if (apiService) {
        if (this.jwt) {
          data = yield apiService.users.getMe()
          const res = yield apiService.users.find({ id: data.id }, { _limit: 1 })
          if (res.length) {
            data = res[0]
          }
        } else if (this.account) {
          const res = yield apiService.users.find({ username: this.account }, { _limit: 1 })
          if (res.length) {
            data = res[0]
          }
        }
      }

      if (data) {
        // fix mobx issue => not change this
        this.userInfo = { ...data }
        if (data.profile) {
          yield this.setFractalUser(data.profile.fractal_user)
          this.userProfile = { ...data?.profile }
        }
        //end
      }
      return !!data
    } catch (error: any) {
      snackController.commonError(error)
    }
  }

  @asyncAction *setFractalUser(id) {
    if (!id || !this.jwt) return
    this.fractalUser = yield apiService.fractalUsers.findOne(id)
  }

  @computed get isLogin() {
    return !!this.jwt && !!this.userProfile
  }

  @action.bound connectWalletForMinting() {
    const currentRouter = appProvider.router?.currentRoute?.path
    if (!localData.getTargetRouter() && !!currentRouter) localData.setTargetRouter(currentRouter)
    this.showConnectWalletDialog(true)
  }

  @action verifyConnectingWallet(): boolean {
    // if (!this.userProfile?.walletAddress) {
    //   this.changeOpenRequireActionConnectWallet(true)
    //   return false
    // }
    if (!this.walletConnected) {
      this.showConnectWalletDialog(true)
      return false
    }
    return true
  }

  @action verifyUserAction() {
    if (!this.jwt) {
      this.changeOpenRequireActionConnectWallet(true)
      return false
    } else if (!this.isKycVerified) {
      this.changeOpenRequireActionVerifyKyc(true)
      return false
    }
    return true
  }

  @action changeOpenKycWarningDialog(value) {
    this.openKycWarningDialog = value
  }

  @action shareProfile() {
    navigator.clipboard.writeText(`${process.env.VUE_APP_HOST}profile/${this.userProfile?.unique_id}`)
    snackController.success('Copied to clipboard')
  }

  @computed get isKycPending() {
    return this.fractalUser?.status === 'pending'
  }
  @computed get isKycFinalRejected() {
    // return this.fractalUser?.status === 'banned'
    return false
  }
  @computed get isKycRejected() {
    return this.fractalUser?.status === 'rejected'
  }
  @computed get isKycVerified() {
    return this.fractalUser?.status === 'approved'
  }
  @computed get kycStatusText() {
    if (this.isKycVerified) return { color: 'primary', text: 'Verified' }
    else if (this.isKycPending) return { color: 'error', text: 'Pending' }
    else if (this.isKycRejected) return { color: 'error', text: 'Rejected' }
    return { color: 'error', text: 'Unverified' }
  }

  @computed get walletConnected() {
    return !!this.account
  }

  @computed get shortAccount() {
    if (!this.account) return ''
    return this.account.substr(0, 4) + '...' + this.account.substr(this.account.length - 4)
  }

  @computed get shortAccount2() {
    if (!this.account) return ''
    return this.account.substr(0, 15) + '...' + this.account.substr(this.account.length - 8)
  }

  @computed get walletLast4Characters() {
    if (!this.account) return ''
    return this.account.slice(-4)
  }

  @computed get isRegisted() {
    return !!this.userProfile
  }

  @computed get isSocialLogin() {
    return this.userInfo && this.userInfo.provider !== 'local'
  }

  @computed get kycStatus(): KYCStatus {
    if (this.isKycVerified)
      return {
        title: 'Your account is verified',
        subTitle: 'Access all features on Thinkin are ready!',
        btnText: 'KYC detail',
      }
    else if (this.isKycPending)
      return {
        title: 'KYC Submitted. Please wait for reviewing',
        subTitle: 'Complete verification to access features on Thinkin',
        btnText: 'Submit KYC',
      }
    else if (this.isKycRejected || this.isKycFinalRejected)
      return {
        title: 'KYC Rejected. Your account is not verified',
        subTitle: 'It’s such a pity, your KYC is rejected. Please check and submit again.',
        btnText: 'KYC detail',
      }
    else
      return {
        title: 'Your account is currently not verified',
        subTitle: 'Complete verification to access features on Thinkin',
        btnText: 'Submit KYC',
      }
  }
}

export const walletStore = new WalletStore()
