import { localData } from '@/stores/local-data'
import { walletStore } from '@/stores/wallet-store'
import { apiService } from '../api-services'
import { ApiRouteType } from '../types'

export class RecruitmentHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}

  async fetchRecruitmentPosts<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: 1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const jwt = localData.getAccessToken()
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`${this.route}/fetch-recruitment-posts`, {
      params,
      headers,
    })
    return res.data
  }

  async fetchMyPostedJobs<T>(
    params?: any,
    settings: { _sort?: string; pageSize?: number; page?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', pageSize: 9, page: 1 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const jwt = localData.getAccessToken()
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`${this.route}/fetch-my-posted-jobs`, {
      params,
      headers,
    })
    return res.data
  }

  async fetchMyApplies<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: 1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const jwt = localData.getAccessToken()
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`${this.route}/fetch-my-applies`, {
      params,
      headers,
    })
    return res.data
  }
}
