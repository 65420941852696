import { localData } from '@/stores/local-data'
import { ApiRouteType } from '../types'

export class DaoHandler<T> {
  constructor(private axios: any, private route: ApiRouteType) {}
  async findOne<T>(id: any): Promise<T> {
    let res: any
    if (id) {
      res = await this.axios.get(`${this.route}/${id}`, {
        headers: { Authorization: `Bearer ${localData.getAccessToken()}` },
      })
    } else {
      res = await this.axios.get(`${this.route}`)
    }
    const result = res.data
    return result
  }

  async find<T>(params?: any, settings: { _sort?: string; _limit?: number; _start?: number } = {}): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:ASC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(this.route, { params })
    const lst = res.data
    return lst
  }

  //hardcode id_nin=> filter to exclude recruitment_id
  async fetchDaos<T>(params?: any, settings: { _sort?: string; _limit?: number } = {}): Promise<T> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}), id_nin: [process.env.VUE_APP_RECRUITMENT_ID] }
    const res = await this.axios.get(`fetch-daos`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    const result = res.data
    return result
  }

  async count(params?: any): Promise<number> {
    const res = await this.axios.get(`${this.route}/count`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async createDao(model: T): Promise<T> {
    const res = await this.axios.post(`${this.route}/create-dao`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }
  async updateDao(model: T): Promise<T> {
    const res = await this.axios.post(`${this.route}/update-dao`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }
  async updateCoverDAO(model: any): Promise<T> {
    const res = await this.axios.put(`${this.route}/${model.daoId}/update-cover`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async deleteDao(id: any): Promise<T> {
    const res = await this.axios.post(
      `${this.route}/${id}/delete`,
      {},
      {
        headers: { ...this.axios.defaults.headers, Authorization: `Bearer ${localData.getAccessToken()}` },
      }
    )
    return res.data
  }

  async upload(file: Blob) {
    const formData = new FormData()
    formData.append('files', file)
    const res = await this.axios.post(`upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  }
  
  async joinDao(model: T): Promise<T> {
    const res = await this.axios.post(`${this.route}/join-dao`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async leaveDao(model: T): Promise<T> {
    const res = await this.axios.post(`${this.route}/leave-dao`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async checkDaoMember(model: T): Promise<T> {
    const res = await this.axios.post(`${this.route}/check-dao-member`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async getDaoFollowState<T>(
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:DESC', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(`/get-daos-follow-state`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async getMostActiveDaos<T>(params?: any): Promise<T[]> {
    let res
    if (localData.getAccessToken()) {
      res = await this.axios.get(`/get-most-active-daos`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      })
    } else {
      res = await this.axios.get(`/get-most-active-daos`, {
        params,
      })
    }
    return res.data
  }

  async getDaosToJoin<T>(params?: any): Promise<T[]> {
    let res
    if (localData.getAccessToken()) {
      res = await this.axios.get(`/get-daos-to-join`, {
        params,
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      })
    } else {
      res = await this.axios.get(`/get-daos-to-join`, {
        params,
      })
    }
    return res.data
  }

  async getContributors<T>(daoId: string) {
    const res = await this.axios.get(`/get-contributors/${daoId}`, {
      params: {},
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }
  async fetchDao<T>(daoId: string) {
    const res = await this.axios.get(`/fetch-dao/${daoId}`, {
      params: {},
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async searchDao<T>(text: string): Promise<T[]> {
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    const jwt = localData.getAccessToken()
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.get(`/search/dao?text=${text}`, { params: {}, headers })
    return res.data
  }

  async searchPost<T>(text: string, daoId: string, daoMenu?: string | null): Promise<T[]> {
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    const jwt = localData.getAccessToken()
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    let res
    if (!daoMenu) res = await this.axios.get(`/search/post?text=${text}&dao=${daoId}`, { params: {}, headers })
    else
      res = await this.axios.get(`/search/post?text=${text}&dao=${daoId}&daoMenu=${daoMenu}`, { params: {}, headers })
    return res.data
  }

  async getAllRelatedDaos<T>(): Promise<T[]> {
    const res = await this.axios.get(`/get-all-related-daos`, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async changeDaoType(daoId: string, body: { type: string }) {
    const res = await this.axios.post(`daos/${daoId}/update-dao-type`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async changeInvitation(daoId: string, body: { invitation: boolean }) {
    const res = await this.axios.post(`daos/${daoId}/set-invitation`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async approveMemberRequest(daoId: string, body: { requestId: string }) {
    const res = await this.axios.post(`daos/${daoId}/approve-join-dao-request`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async rejectMemberRequest(daoId: string, body: { requestId: string; rejectReason: string }) {
    const res = await this.axios.post(`daos/${daoId}/reject-join-dao-request`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async updateMemberRole<T>(id: string, body: { profile: string; role: string }): Promise<T[]> {
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    const jwt = localData.getAccessToken()
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.post(`/daos/${id}/update-member-role`, body, { headers })
    return res.data
  }
  async removeMember<T>(id: string, body: { profile: string }): Promise<T[]> {
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    const jwt = localData.getAccessToken()
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.post(`/daos/${id}/remove-member`, body, { headers })
    return res.data
  }
  async blockMember<T>(id: string, body: { profile: string }): Promise<T[]> {
    let headers = {
      ...this.axios.defaults.headers,
    } as any
    const jwt = localData.getAccessToken()
    if (jwt) headers = { ...headers, Authorization: `Bearer ${jwt}` }
    const res = await this.axios.post(`/daos/${id}/block-member`, body, { headers })
    return res.data
  }
  async applyToJoin(daoId: string) {
    const res = await this.axios.post(
      `daos/${daoId}/apply-to-join`,
      {},
      {
        headers: {
          ...this.axios.defaults.headers,
          Authorization: `Bearer ${localData.getAccessToken()}`,
        },
      }
    )
    return res.data
  }

  async fetchUserToInvite<T>(
    daoId: string,
    params?: any,
    settings: { _sort?: string; _limit?: number; _start?: number } = {}
  ): Promise<T[]> {
    const settingDefault = { _sort: 'createdAt:desc', _limit: -1, _start: 0 }
    params = { ...settingDefault, ...settings, ...(params ?? {}) }
    const res = await this.axios.get(`daos/${daoId}/fetch-users-invite`, { params })
    const lst = res.data
    return lst
  }

  async inviteJoinDao(daoId: string, body: { receivers: string[] }) {
    const res = await this.axios.post(`daos/${daoId}/invite-join-dao`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async getInvites<T>(params): Promise<T[]> {
    const res = await this.axios.get(`/get-invites`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async acceptInvite(body: { inviteId: string }) {
    const res = await this.axios.post(`/accept-dao-invite`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async rejectInvite(body: { inviteId: string }) {
    const res = await this.axios.post(`/reject-dao-invite`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async generateInviteLink(daoId: string, body: { expiredTime: string; name: string }) {
    const res = await this.axios.post(`/daos/${daoId}/generate-invite-link`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async deleteInviteLink(body: { inviteLinkId: string }) {
    const res = await this.axios.post(`/delete-invite-link`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async acceptInviteLink(body: { inviteLinkId: string }) {
    const res = await this.axios.post(`/accept-invite-link`, body, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async getInviteLinks<T>(daoId: string, params): Promise<T[]> {
    const res = await this.axios.get(`/daos/${daoId}/get-invite-links`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async fetchReportedPosts<T>(daoId: string): Promise<T[]> {
    const res = await this.axios.get(`/daos/${daoId}/fetch-reported-posts`, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async checkDuplicateDaoName(model: { name: string; daoId?: string }): Promise<T> {
    const res = await this.axios.post(`${this.route}/check-duplicate-dao-name`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async fetchDaoPosts(daoId: string, params: any) {
    const res = await this.axios.get(`/daos/${daoId}/fetch-dao-posts`, {
      params,
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }

  async pinPost(daoId: string, model: { postId: string }) {
    const res = await this.axios.post(`${this.route}/${daoId}/pin-post`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }
  async unPinPost(daoId: string, model: { postId: string }) {
    const res = await this.axios.post(`${this.route}/${daoId}/unpin-post`, model, {
      headers: {
        ...this.axios.defaults.headers,
        Authorization: `Bearer ${localData.getAccessToken()}`,
      },
    })
    return res.data
  }
  async fetchPinnedPosts(daoId: string) {
    const res = await this.axios.get(`${this.route}/${daoId}/fetch-pinned-posts`)
    return res.data
  }
}
